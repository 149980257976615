import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomInputsContext from '#context/custom_inputs.jsx';
import ModalsContext from '#context/modals.jsx';
import OrganizationContext from '#context/organization';
import Loader from '#components/loader.jsx';

const _information = [
	{ name: 'Text Field Input', value: 'First Name' },
	{ name: 'Text Field Input', value: 'Last Name' },
	{ name: 'Text Field Input', value: 'Email' },
	{ name: 'Text Field Input', value: 'CC Email Address(es) for Notifications' },
	{ name: 'Number Input', value: 'Phone Number' },
	{ name: 'Text Field Input', value: 'Session Name' },
	{ name: 'Number Input', value: 'Number of Attendees' },
	{ name: 'Area Text Field Input', value: 'Details' },
];

const List = () => {
	const location = useLocation();
	const inputs_context = useContext(CustomInputsContext);
	const modals_context = useContext(ModalsContext);
	const { list, get_list, api } = inputs_context;

	const [dragged_item, set_dragged_item] = useState(null);
	const [current_list, set_current_list] = useState();

	const drag_enter = (e, i) => {
		e.preventDefault();
		e.stopPropagation();

		const new_list = [...current_list];
		const content = new_list[dragged_item];

		new_list.splice(dragged_item, 1);
		new_list.splice(i, 0, content);

		set_dragged_item(i);
		set_current_list(new_list);
	};

	const drag_end = () => {
		set_dragged_item();
		api.update_indexes(current_list);
	};

	useEffect(() => {
		get_list();
	}, []);

	useEffect(() => {
		set_current_list(list);
	}, [list]);

	if (!current_list) return <Loader />;

	if (!current_list.length) {
		return <p className="no_items">Currently, there are no custom inputs.</p>;
	}

	return (
		<ul className="list">
			{current_list.map((input, i) => (
				<li
					key={i}
					className={`${location.hash.includes(input._id) ? 'active' : ''}${dragged_item === i ? ' dragging' : ''}`}
					draggable="true"
					onDragStart={() => set_dragged_item(i)}
					onDragEnter={(e) => drag_enter(e, i)}
					onDragOver={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					onDragEnd={drag_end}
				>
					<div className={`info${input.type === 'Note' ? ' type_note' : ''}`}>
						<span>{input.type}</span>
						{input.title ? <p dangerouslySetInnerHTML={{ __html: input.title }}></p> : <></>}
					</div>
					<div className="actions">
						{input.type === 'Divider' ? <></> : <a className="icon edit" href={`#input_${input._id}`} onClick={() => inputs_context.set(input)}></a>}
						<a
							className="icon delete_red"
							onClick={() =>
								modals_context.show('custom_input_delete', {
									clb: () => api.delete(input._id),
									text: `Are you sure you want to remove <b>${input.title}</b>? You can’t undo this action.`,
								})
							}
						></a>
					</div>
				</li>
			))}
		</ul>
	);
};

const CustomInputsHeader = () => {
	const navigate = useNavigate();
	const modals_context = useContext(ModalsContext);
	const inputs_context = useContext(CustomInputsContext);

	return (
		<>
			<div className="header_block">
				<h1>Custom Form Inputs</h1>
				<a
					className="button approve"
					onClick={() => {
						modals_context.show('custom_input_add', {
							clb: (type) => {
								if (type === 'Divider') {
									inputs_context.api.create({ type });
									return;
								}

								navigate('#input');
								inputs_context.set({ type });
							},
						});
					}}
				>
					+Add Field
				</a>
			</div>
			<p>Create custom form fields below to collect additional information from users during the booking process.</p>
		</>
	);
};
const CustomInputs = () => (
	<div className="block">
		<CustomInputsHeader />
		<List />
	</div>
);

const Information = () => {
	const organization_context = useContext(OrganizationContext);

	return (
		<div className="block">
			<h3>Required Session Information</h3>
			<ul className="list">
				{_information.map(({ name, value }, i) => (
					<li key={i}>
						<div className="info">
							<span>{name}</span>
							<p>{value}</p>
						</div>
					</li>
				))}
			</ul>
			<div className="checkbox">
				<input
					type="checkbox"
					id="enable_file_upload"
					name="enable_file_upload"
					checked={organization_context.organization.file_upload}
					onChange={(e) => organization_context.file_upload(e.target.checked)}
				/>
				<label htmlFor="enable_file_upload">Enable File Upload</label>
			</div>
		</div>
	);
};

const Header = () => (
	<div className="header_block">
		<h1>Booking Request Form</h1>
	</div>
);

const RequestFormView = () => (
	<section className="dashboard_list request_form_list">
		<Header />
		<Information />
		<CustomInputs />
	</section>
);

export default RequestFormView;
