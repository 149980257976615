import { BtnBold, BtnItalic, createButton, BtnUnderline, Editor, EditorProvider, Toolbar } from 'react-simple-wysiwyg';

const BtnLink = createButton('Link', <span className="rsw-icon-link"></span>, (a) => {
	const $selection = a.$selection;
	if (($selection === null || $selection === void 0 ? void 0 : $selection.nodeName) === 'A') {
		document.execCommand('unlink');
	} else {
		document.execCommand('createLink', false, prompt('Enter a URL:', 'https://') || undefined);
	}
});

const WYSIWYGTextArea = ({ name, placeholder = '', value = '', required, readonly, onChange = () => {} }) => {
	return (
		<>
			<EditorProvider>
				<Editor
					name={name}
					value={value}
					onChange={(e) => {
						e.target.value = e.target.value
							.replace(/<a\s(target="blank")?\s?/gi, '<a target="blank" ')
							.replace(/href="(\S*)"/gi, (match, content) => {
								if (!content.startsWith('http') && !content.startsWith('//')) content = '//' + content;
								return `href="${content}"`;
							});
						onChange(e);
					}}
					required={required}
					placeholder={placeholder}
					containerProps={{
						className: `rsw-editor${readonly ? ' readonly' : ''}`,
					}}
				>
					<Toolbar>
						<BtnBold />
						<BtnItalic />
						<BtnUnderline />
						<BtnLink className="rsw-btn-link">Hello world</BtnLink>
					</Toolbar>
					{required ? <textarea required readOnly className="rsw-textarea" value={value} onChange={() => {}} /> : <></>}
				</Editor>
			</EditorProvider>
		</>
	);
};

export default WYSIWYGTextArea;
