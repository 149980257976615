import React, { useState, useContext, useEffect } from 'react';

import OrganizationContext from '#context/organization.jsx';
import UserContext from '#context/user.jsx';

import api_bookings from '#api/bookings.js';
import api_rooms from '#api/rooms.js';
import api_custom_inputs from '#api/custom_inputs.js';

import { format_date_YYYY_MM } from '#components/time_helpers.js';

const NewBookingContext = React.createContext();

const _form_fields = ['first_name', 'last_name', 'email', 'phone', 'session', 'attendees', 'details'];

const get_fields = () => {
	const fields = {};
	_form_fields.forEach((field) => (fields[field] = field = ''));
	return fields;
};

function NewBookingProvider(props) {
	const { organization } = useContext(OrganizationContext);
	const { session } = useContext(UserContext);

	const [rooms, rooms_set] = useState([]);
	const [selected_rooms, selected_rooms_set] = useState({});
	const [duration, duration_set] = useState(0);
	const [cleanup, cleanup_set] = useState(undefined);
	const [setup, setup_set] = useState(undefined);

	const [warning, warning_set] = useState(false);
	const [custom_fields, set_custom_fields] = useState([]);
	const [form_data, set_form_data] = useState(get_fields);
	const [files, set_files] = useState([]);
	const [custom_data, set_custom_data] = useState({});

	const get_custom_inputs = async () => {
		try {
			const response = await api_custom_inputs.list();
			if (response.length === 0) return;
			set_custom_fields(response);
			const custom_inputs = {};
			response.filter((input) => input.type !== 'Note').forEach(({ _id }) => (custom_inputs[_id] = ''));
			set_custom_data(custom_inputs);
		} catch (e) {
			console.log(e);
		}
	};

	const [timeslots, timeslots_set] = useState({});
	const [selected_timeslots, selected_timeslots_set] = useState([{ date: undefined, time: undefined }]);

	const get_rooms = async () => {
		store.rooms = await api_rooms.for_new_booking();
	};

	const _check_max_duration = (selected_rooms) => {
		let max_duration = Math.min(
			...Array.from(Object.entries(selected_rooms))
				.filter(([_id, count]) => !!count)
				.map(([_id]) => {
					let room = rooms.find((room) => room._id === _id);
					if (!room) return 0; //How is it possible?
					return store.get_max_duration_for_room(room);
				})
		);

		if (duration > max_duration) {
			warning_set('We changed the duration because the maximum duration of one of the selected rooms is less.');
			duration_set(max_duration);
		} else {
			warning_set(false);
		}
	};

	useEffect(() => {
		let changed = 0;
		Array.from(Object.entries(selected_rooms))
			.filter(([_id, count]) => !!count)
			.forEach(([_id]) => {
				let room = rooms.find((room) => room._id === _id);
				if (!room) return;
				if (store.get_max_duration_for_room(room) <= 0) {
					changed++;
					selected_rooms[_id] = 0;
				}
			});

		_check_max_duration(selected_rooms);

		if (changed) {
			store.selected_rooms = { ...selected_rooms };
			warning_set(
				`Setup & Cleanup Time exceed available duration on selected room${changed > 1 ? 's' : ''}. We have removed room${
					changed > 1 ? 's' : ''
				} effected. Please select room(s) with available duration or reduce Setup & Cleanup Time.`
			);
		}
	}, [cleanup, setup]);

	useEffect(() => {
		get_rooms();
		get_custom_inputs();
	}, []);

	const store = {
		get rooms() {
			return rooms;
		},
		set rooms(v) {
			rooms_set(v);
		},
		get selected_rooms() {
			return selected_rooms;
		},
		set selected_rooms(v) {
			selected_rooms_set(v);
			selected_timeslots_set([{ date: undefined, time: undefined }]);
			timeslots_set({});

			_check_max_duration(v);
		},
		get duration() {
			return duration;
		},
		set duration(v) {
			warning_set(false);
			selected_timeslots_set([{ date: undefined, time: undefined }]);
			timeslots_set({});

			duration_set(v);
		},
		get cleanup() {
			return cleanup;
		},
		set cleanup(v) {
			warning_set(false);
			cleanup_set(v);
		},
		get setup() {
			return setup;
		},
		set setup(v) {
			warning_set(false);
			setup_set(v);
		},

		get timeslots() {
			return timeslots;
		},
		set timeslots(v) {
			timeslots_set(v);
		},

		download_timeslots({ year, month }) {
			if (!year || !month) return;

			let formatted = format_date_YYYY_MM({ year, month });

			let current = timeslots[formatted];

			if (current) {
				if (current instanceof Promise) return current;

				if (current instanceof Object && Object.keys(current).length) return;
			}

			timeslots[formatted] = new Promise(async (resolve) => {
				try {
					timeslots[formatted] = await api_bookings.timeslots({
						year,
						month,
						rooms: selected_rooms,
						duration,
						setup,
						cleanup,
					});
				} catch (e) {
					timeslots[formatted] = {};
				}
				timeslots_set({ ...timeslots });
				resolve();
			});

			return timeslots[formatted];
		},

		get selected_timeslots() {
			return selected_timeslots;
		},
		set selected_timeslots(v) {
			selected_timeslots_set(v);
		},

		get selected_timeslots_valid() {
			for (let i = 0; i < selected_timeslots.length; i++) {
				if (!selected_timeslots[i].date) return false;
				if (!selected_timeslots[i].time) return false;
			}
			return true;
		},

		get warning() {
			return warning;
		},

		reset() {
			store.rooms = [];
			store.selected_rooms = {};
			store.duration = 0;
			store.cleanup = undefined;
			store.setup = undefined;

			store.timeslots = {};
			store.selected_timeslots = [{ date: undefined, time: undefined }];

			store.form_data = {};
			store.files = [];
			store.custom_data = {};

			get_rooms();
		},

		get total_rooms() {
			return Array.from(Object.values(selected_rooms)).reduce((total, count) => total + count, 0);
		},
		get durations() {
			let max_duration = Math.min(
				...Array.from(Object.entries(selected_rooms))
					.filter(([_id, count]) => !!count)
					.map(([_id]) => {
						let room = rooms.find((room) => room._id === _id);
						if (!room) return 0; //How is it possible?
						return store.get_max_duration_for_room(room);
					})
			);

			let durations = [];

			if (max_duration !== Infinity) for (let i = 0.5; i <= max_duration; i += 0.5) durations.push(i);

			return durations;
		},
		get form_data() {
			return form_data;
		},
		set form_data(v) {
			set_form_data(v);
		},
		get custom_fields() {
			return custom_fields;
		},
		get files() {
			return files;
		},
		set files(v) {
			set_files(v);
		},
		get custom_data() {
			return custom_data;
		},
		set custom_data(v) {
			set_custom_data(v);
		},

		get_max_duration_for_room(room) {
			if (!organization) return '';
			let max_duration = organization.max_duration;
			max_duration -= organization.edit_times && setup !== undefined ? setup : room.setup;
			max_duration -= organization.edit_times && cleanup !== undefined ? cleanup : room.cleanup;
			return max_duration;
		},

		async submit() {
			warning_set('Creating booking...');
			const booking_ids = await api_bookings.create({
				session,
				setup,
				cleanup,
				duration,
				form_data,
				custom_data,
				timeslots: selected_timeslots,
			});

			if (files.length) {
				warning_set('Uploading files...');
				await Promise.all(
					files.map((file) =>
						api_bookings.create_files({
							session,
							file,
							booking_ids,
						})
					)
				);
			}

			warning_set(false);

			return booking_ids;
		},
	};

	return <NewBookingContext.Provider value={store}>{props.children}</NewBookingContext.Provider>;
}

export default NewBookingContext;
export { NewBookingProvider };
