import React, { useContext, useState, useEffect } from 'react';

import ModalsContext from '#context/modals.jsx';
import SignIn from '#components/sign_in_form.jsx';
import CreateAccount from '#components/create_account_form.jsx';

const Authentication = () => {
	const modals_context = useContext(ModalsContext);
	const [state, set_state] = useState('sign_in');

	let content;

	useEffect(() => {
		modals_context.data = { ...modals_context.data, state };
	}, [state]);

	let clb = (user) => {
		modals_context.hide();
		modals_context.data.clb(user);
	};

	switch (state) {
		case 'sign_in':
			content = (
				<>
					<SignIn
						submit_callback={clb}
						forgot_password_callback={(email) => {
							modals_context.show('forgot_password', { initial_email: email });
						}}
						create_account_callback={() => {
							set_state('create_acccount');
						}}
					/>
					<div className="separator">or</div>
					<a className="btn_continue_guest" href="#!" onClick={clb}>
						Continue as a Guest
					</a>
				</>
			);
			break;
		case 'create_acccount':
			content = <CreateAccount submit_callback={clb} sign_in_callback={() => set_state('sign_in')} />;
			break;
		default:
			break;
	}

	return (
		<div className="popup sign_in">
			<a className="btn_close" href="#!" onClick={modals_context.hide}></a>
			{content}
		</div>
	);
};

export default Authentication;
