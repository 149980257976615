import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Headerbar from '#components/headerbar.jsx';
import DropdownCalendar from '#components/dropdown_calendar.jsx';
import { generateCalendar } from '#components/dropdown_calendar.jsx';
import api_bookings from '#api/bookings.js';

import { DateTime } from 'luxon';

import Loader from '#components/loader.jsx';
import { prettier } from '#components/time_helpers.js';
import OrganizationContext from '#context/organization.jsx';

import { get_rooms } from '#utils/index.jsx';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const Calendar = ({ loading, on_month_change, clb, selection, dates_cls, disabled }) => {
	const minimum = DateTime.now();
	const { year, month } = selection;

	let click_prev = () => {
		let date = DateTime.local(year, month, 1).minus({ months: 1 });
		on_month_change(date);
	};

	if (year < minimum.year) {
		click_prev = undefined;
	} else if (year === minimum.year) {
		if (month <= minimum.month) {
			click_prev = undefined;
		} else if (month === minimum.month) {
			click_prev = undefined;
		}
	}

	const click_next = () => {
		let date = DateTime.local(year, month, 1).plus({ months: 1 });
		on_month_change(date);
	};

	return (
		<div className={`calendar_content desktop${disabled ? ' disabled' : ''}`}>
			<div className="header">
				<div className={`arrow left${!click_prev ? ' disabled' : ''}`} onClick={click_prev}></div>
				<div className="current_date">{`${months[month - 1]} ${year}`}</div>
				<div className="arrow right" onClick={click_next}></div>
			</div>
			<div className="body">
				<ul className="weekdays">
					<li>S</li>
					<li>M</li>
					<li>T</li>
					<li>W</li>
					<li>T</li>
					<li>F</li>
					<li>S</li>
				</ul>
				<ul className="days">
					{generateCalendar({
						year,
						month,
						dates_cls,
						clb: (value) => {
							clb(value);
						},
					})}
				</ul>
				{loading && (
					<div className="overlay">
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
};

const Session = ({ session }) => {
	const [collapsed, set_collapsed] = useState(true);

	let rooms = get_rooms(session.schedule?.rooms).split('\n');
	if (rooms.length > 3) {
		if (collapsed) {
			rooms.length = 2;
			rooms = (
				<>
					{rooms.map((room) => (
						<span key={room}>{room}</span>
					))}
					<a href="#!" onClick={() => set_collapsed(false)}>
						See more
					</a>
				</>
			);
		} else {
			rooms = (
				<>
					{rooms.map((room) => (
						<span key={room}>{room}</span>
					))}
					<a href="#!" onClick={() => set_collapsed(true)}>
						See less
					</a>
				</>
			);
		}
	} else {
		rooms = rooms.map((room) => <span key={room}>{room}</span>);
	}

	return (
		<li className={session.status === 'pending' ? 'unavailable' : ''}>
			<div className="info">
				<div className="schedule">
					<div className="date">{DateTime.fromMillis(session.schedule.timestamp, { zone: 'utc' }).toISODate()}</div>
					<div className="time">
						{prettier(session.schedule.times[1].start)} - {prettier(session.schedule.times[1].end)}
					</div>
				</div>
				<div className="place">{session.requestInformation.session_name}</div>
				<div className="cabinet">{rooms}</div>
			</div>
		</li>
	);
};

const List = ({ loading, sessions, query, error, year, month, selected_date }) => {
	if (error) return <span className="error">error</span>;

	if (loading) return <Loader />;

	if (sessions.length === 0) {
		if (query) return <div className="no_match_warning">We’re sorry. We were not be able to find a match. Try another search?</div>;
		if (year === selected_date.year && month === selected_date.month)
			return <div className="no_sessions_warning">There are no sessions on this date.</div>;
		return <div className="no_sessions_warning">Please choose a date to see scheduled sessions.</div>;
	}

	return (
		<>
			<ul className="suggestions">
				{sessions.map((session, i) => {
					return <Session session={session} key={i} />;
				})}
			</ul>
			<div className="total">Session Total: {sessions.length}</div>
		</>
	);
};

const CalendarPage = () => {
	const date_now = DateTime.now();
	const timer_id = useRef(null);
	const navigate = useNavigate();

	const { organization } = useContext(OrganizationContext);

	const [year, set_year] = useState(date_now.year);
	const [month, set_month] = useState(date_now.month);
	const [selected_date, set_selected_date] = useState(date_now);

	const [loading, set_loading] = useState(false);
	const [error, set_error] = useState(null);
	const [sessions, set_sessions] = useState({});

	const [query, set_query] = useState('');

	const cls = {};

	for (let day = 1; day <= 31; day++) {
		const weekday = DateTime.local(year, month, day).weekday;

		if (weekday && organization.hours[weekday][0] === 0 && organization.hours[weekday][1] === 0) {
			cls[day] = 'unavailable';
			continue;
		}

		cls[day] = '';

		if (sessions[day]) {
			cls[day] = 'event';
		}

		if (day === selected_date.day && year === selected_date.year && month === selected_date.month && !query) {
			cls[selected_date.day] = `${cls[selected_date.day]} active`;
		}

		if (month === date_now.month && year === date_now.year) {
			for (let day = 1; day < date_now.day; day++) {
				cls[day] = `${cls[day]} unavailable`;
			}
		}
	}

	let show_sessions = query
		? Object.values(sessions).reduce((arr, day) => {
				arr.push(...day);
				return arr;
			}, [])
		: year === selected_date.year && month === selected_date.month
			? sessions[selected_date.day] || []
			: [];

	const request_on_server = async (query) => {
		set_loading(true);

		try {
			const result = await api_bookings.public_calendar(query);
			set_sessions(result);
		} catch (error) {
			set_error(error);
		} finally {
			set_loading(false);
		}
	};

	useEffect(() => {
		request_on_server({ year, month }, true);
	}, []);

	const on_month_change = ({ year, month }) => {
		set_year(year);
		set_month(month);
		set_sessions({});
		request_on_server({ year, month, query });
	};

	const on_change = (e) => {
		const query = e.target.value;
		set_query(query);

		clearTimeout(timer_id.current);
		timer_id.current = setTimeout(() => {
			request_on_server({ year, month, query });
		}, 350);
	};

	const on_close = () => {
		set_query('');
		request_on_server({ year, month });
	};

	return (
		<div className="app2">
			<Headerbar page="/calendar" back_clb={() => navigate(-1)} disabled={loading} />
			<div className={`search_calendar${query ? ' searching' : ''}`}>
				<h1>Calendar</h1>
				<div className="wrapper">
					<Calendar
						selection={{ year, month }}
						loading={loading}
						on_month_change={on_month_change}
						clb={(date) => {
							set_selected_date(DateTime.fromISO(date));
						}}
						dates_cls={cls}
						disabled={!!query}
					/>
					<div className="search">
						<div className="search_fields">
							<DropdownCalendar
								cls="mobile"
								selection={selected_date.toISODate()}
								loading={loading}
								on_month_change={on_month_change}
								clb={(date) => {
									set_selected_date(DateTime.fromISO(date));
								}}
								dates_cls={cls}
								minimum={DateTime.now()}
							/>
							<div className="input">
								<input type="text" name="search" id="search" placeholder="Search this month" value={query} onChange={on_change} />
								<a href="#!" className="btn_close" alt="Button close" onClick={on_close}></a>
							</div>
						</div>
						<List loading={loading} sessions={show_sessions} query={query} error={error} year={year} month={month} selected_date={selected_date} />
					</div>
				</div>
			</div>
		</div>
	);
};
export default CalendarPage;
