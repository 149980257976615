import { useContext } from 'react';

import ModalsContext from '#context/modals.jsx';

const _inputs = [
	{ type: 'Text Field Input', cls: 'text' },
	{ type: 'Long Text Field Input', cls: 'long_text' },
	{ type: 'Checkboxes', cls: 'checkbox' },
	{ type: 'Radio', cls: 'radio' },
];

const _design = [
	{ type: 'Note', cls: 'note' },
	{ type: 'Divider', cls: 'divider' },
];

const CustomInputAdd = ({ clb }) => {
	const modals_context = useContext(ModalsContext);

	return (
		<div className={`popup custom_input_add`}>
			<a className="btn_close" href="#" onClick={modals_context.hide}></a>
			<h2>Select Field Type</h2>
			<h3>Inputs:</h3>
			<div className="types">
				{_inputs.map(({ type, cls }) => (
					<div
						key={type}
						tabIndex={0}
						onClick={() => {
							modals_context.hide();
							clb(type);
						}}
					>
						<span className={cls} />
						<p>{`${type.replace('Field Input', '')} ${type === 'Radio' ? 'Button' : ''}`}</p>
					</div>
				))}
			</div>
			<h3>Design Elements:</h3>
			<div className="types">
				{_design.map(({ type, cls }) => (
					<div
						key={type}
						tabIndex={0}
						onClick={() => {
							modals_context.hide();
							clb(type);
						}}
					>
						<span className={cls} />
						<p>{`${type.replace('Field Input', '')} ${type === 'Radio' ? 'Button' : ''}`}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default CustomInputAdd;
