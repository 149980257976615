import React, { useState, useContext } from 'react';

import api_rooms from '#api/rooms.js';

import UserContext from '#context/user.jsx';

const RoomsContext = React.createContext();

const _fields_to_update = ['name', '_id'];

function RoomsProvider(props) {
	const { session } = useContext(UserContext);
	const [list, set_list] = useState(undefined);
	const [room, set_room] = useState(undefined);

	const get = async (room) => {
		set_room(undefined);
		const data = await api_rooms.get({ session, room });
		set_room(data);
		return data;
	};

	const update = async (data) => {
		const result = await api_rooms.update({ session, room: data._id, ...data });
		set_room(result);

		const list_entry = list.find(({ _id }) => _id === result._id);
		if (list_entry) {
			_fields_to_update.forEach((field) => (list_entry[field] = result[field]));
			list_entry.count = result.rooms.length;
			set_list([...list]);
		}
	};

	const create = async (data) => {
		const result = await api_rooms.create({ session, ...data });
		set_room(result);

		const list_entry = {};
		_fields_to_update.map((field) => (list_entry[field] = result[field]));
		list_entry.count = result.rooms.length;
		set_list([...list, list_entry]);
	};

	const get_list = async () => {
		set_list(undefined);
		set_list(await api_rooms.list_types(session));
	};

	const _delete = async (room) => {
		const index = list.findIndex((item) => item._id === room);
		if (index === -1) {
			return;
		}

		await api_rooms.delete({ session, room });
		const new_list = [...list];
		new_list.splice(index, 1);
		set_list(new_list);
	};

	const update_indexes = async (data) => {
		set_list(data);
		const indexes = data.map((room, index) => ({
			room: room._id,
			index,
		}));
		await api_rooms.update_indexes({ session, indexes });
	};

	const store = {
		get room() {
			return room;
		},
		set room(_) {},
		get list() {
			return list;
		},
		set list(_) {},
		get_list,
		get,
		api: {
			create,
			update,
			delete: _delete,
			update_indexes,
		},
	};

	return <RoomsContext.Provider value={store}>{props.children}</RoomsContext.Provider>;
}

export default RoomsContext;
export { RoomsProvider };
