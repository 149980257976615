import React, { useContext } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import NewBookingRooms from '#views/new_booking_rooms.jsx';
import NewBookingDatetimes from '#views/new_booking_datetimes.jsx';
import NewBookingDetails from '#views/new_booking_details.jsx';
import NewBookingFinished from '#views/new_booking_finished.jsx';

import DashboardRequests from '#views/dashboard_requests.jsx';
import DashboardFacility from '#views/dashboard_facility.jsx';
import DashboardNotifications from '#views/dashboard_notifications.jsx';
import DashboardRequestForm from '#views/dashboard_request_form.jsx';
import DashboardRooms from '#views/dashboard_rooms.jsx';
import DashboardAssets from '#views/dashboard_assets.jsx';
import DashboardAnalytics from '#views/dashboard_analytics.jsx';
import FeedbackPageRouter from '#views/feedback.jsx';
import DashboardCalendar from '#views/dashboard_calendar.jsx';
import DashboardFeedback from '#views/dashboard_feedback.jsx';
import DashboardUsers from '#views/dashboard_users.jsx';

import SignInPage from '#views/sign_in.jsx';
import CreateAccountPage from '#views/create_account.jsx';
import BookingView from '#views/booking.jsx';
import ContactUsPage from '#views/contact_us.jsx';

import CalendarPage from '#views/calendar';

import SetPasswordPage from '#views/set_password.jsx';

import '#scss/style.scss';

import CONFIG from '#config.json';

import { NotificationsProvider } from '#context/notifications.jsx';
import UserContext, { UserProvider } from '#context/user.jsx';
import { OrganizationDeffer, OrganizationProvider } from '#context/organization.jsx';
import { ModalsProvider } from '#context/modals.jsx';
import { BookingsProvider } from '#context/bookings.jsx';
import IsMobileContext, { IsMobileProvider } from '#context/is_mobile.jsx';
import { RoomsProvider } from '#context/rooms.jsx';
import { AssetsProvider } from '#context/assets.jsx';
import { CustomInputsProvider } from '#context/custom_inputs.jsx';
import { FeedbackProvider } from '#context/feedback.jsx';
import { UsersProvider } from '#context/users.jsx';

import { NewBookingProvider } from '#context/new_booking.jsx';

import ModalsController from '#controllers/modals.jsx';

import DashboardLayout from '#components/dashboard_layout.jsx';
import Loader from '#components/loader.jsx';

function Root() {
	const user_context = useContext(UserContext);
	const is_mobile = useContext(IsMobileContext);

	if (!user_context.loaded) return <Loader />;

	if (!user_context.user) return <Navigate to="/sign_in" />;

	if (is_mobile) return <Navigate to="/booking" />;

	return <Navigate to="/dashboard" />;
}

function App() {
	return (
		<Router>
			<UserProvider>
				<NotificationsProvider>
					<OrganizationProvider>
						<ModalsProvider>
							<IsMobileProvider>
								<NewBookingProvider>
									<OrganizationDeffer>
										<BookingsProvider>
											<RoomsProvider>
												<AssetsProvider>
													<CustomInputsProvider>
														<FeedbackProvider>
															<UsersProvider>
																<ModalsController />
																<Routes>
																	<Route exact path="/" element={<Root />} />

																	<Route path="/contact_us" element={<ContactUsPage />} />
																	<Route path="/calendar" element={<CalendarPage />} />
																	<Route path="/sign_in" element={<SignInPage />} />
																	<Route path="/create_account" element={<CreateAccountPage />} />
																	<Route path="/set_password/:token" element={<SetPasswordPage />} />

																	<Route path="/new_booking/rooms" element={<NewBookingRooms />} />
																	<Route path="/new_booking/datetimes" element={<NewBookingDatetimes />} />
																	<Route path="/new_booking/details" element={<NewBookingDetails />} />
																	<Route path="/new_booking/finished" element={<NewBookingFinished />} />

																	<Route exact path="/booking" element={<Navigate to="/new_booking/rooms" />} />

																	<Route path="/booking/:_id" element={<BookingView />} />

																	<Route path="/feedback/:token/:rating" element={<FeedbackPageRouter />} />

																	<Route path="/dashboard" element={<DashboardLayout />}>
																		<Route path="" element={<DashboardRequests />} />
																		<Route path="requests" element={<DashboardRequests />} />
																		<Route path="requests/:status" element={<DashboardRequests />} />
																		<Route path="requests/:status/:year/:month" element={<DashboardRequests />} />
																		<Route path="facility" element={<DashboardFacility />} />
																		<Route path="notifications" element={<DashboardNotifications />} />
																		<Route path="notifications/:unseen" element={<DashboardNotifications />} />
																		<Route path="request_form" element={<DashboardRequestForm />} />
																		<Route path="rooms" element={<DashboardRooms />} />
																		<Route path="assets" element={<DashboardAssets />} />
																		<Route path="analytics" element={<DashboardAnalytics />} />
																		<Route path="analytics/:from/:to" element={<DashboardAnalytics />} />
																		<Route path="calendar" element={<DashboardCalendar />} />
																		<Route path="calendar/:date" element={<DashboardCalendar />} />
																		<Route path="feedback_form" element={<DashboardFeedback />} />
																		<Route path="users" element={<DashboardUsers />} />
																		<Route path="users/:role" element={<DashboardUsers />} />
																	</Route>
																</Routes>
															</UsersProvider>
														</FeedbackProvider>
													</CustomInputsProvider>
												</AssetsProvider>
											</RoomsProvider>
										</BookingsProvider>
									</OrganizationDeffer>
								</NewBookingProvider>
							</IsMobileProvider>
						</ModalsProvider>
					</OrganizationProvider>
				</NotificationsProvider>
			</UserProvider>
		</Router>
	);
}

export default App;
