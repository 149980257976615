import CONFIG from '../config.json';

export default async function (route, body) {
	const response = await fetch(`${CONFIG.api}/${route}`, {
		method: 'POST',
		body,
	});

	let data;
	try {
		data = await response.json();
	} catch (e) {
		//JSON is empty, it can be, it's ok
	}

	if (response.ok) {
		return data;
	} else if (response.status === 400) {
		throw data;
	} else {
		throw 'api_error';
	}
}
