import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Headerbar from '#components/headerbar.jsx';
import Stepper from '#components/booking_stepper.jsx';

import Dropdown from '#components/dropdown.jsx';
import DropdownCalendar from '#components/dropdown_calendar.jsx';

import ModalsContext from '#context/modals.jsx';

import NewBookingContext from '#context/new_booking.jsx';

import UserContext from '#context/user.jsx';

import OrganizationContext from '#context/organization.jsx';

import { DateTime } from 'luxon';

import { pretty_period } from '#components/time_helpers.js';

import { format_date_YYYY_MM_DD, format_date_YYYY_MM, pretty_interval } from '#components/time_helpers.js';

const Timeslot = ({ timeslot, n }) => {
	const new_booking_context = useContext(NewBookingContext);
	const { organization } = useContext(OrganizationContext);

	const [dropdown_open, set_dropdown_open] = useState(false);

	const [year, year_set] = useState();
	const [month, month_set] = useState();

	let cls = {};
	let timeslots = new_booking_context.timeslots[format_date_YYYY_MM({ year, month })];

	if (timeslots instanceof Object)
		for (let day = 1; day <= 31; day++) {
			cls[day] = '';

			if (timeslot.date === format_date_YYYY_MM_DD({ year, month, day })) {
				cls[day] = 'active';
				continue;
			}

			if (!timeslots[day]) {
				cls[day] = 'unavailable';
				continue;
			}

			new_booking_context.selected_timeslots.forEach((timeslot, i) => {
				if (i === n) return;

				if (timeslot.date === format_date_YYYY_MM_DD({ year, month, day })) cls[day] = 'unavailable';
			});
		}

	let time_list = [];
	let timeslots_for_time;

	if (timeslot.date) {
		timeslots_for_time = new_booking_context.timeslots[timeslot.date.substr(0, 7)][+timeslot.date.split('-')[2]];
		time_list = timeslots_for_time.map(({ time }) => ({
			view: pretty_interval(time, time + new_booking_context.duration),
			value: time,
			chosen: time === timeslot.time,
		}));
	}

	let loading = new_booking_context.timeslots[format_date_YYYY_MM({ year, month })];

	loading = loading === undefined || loading instanceof Promise;

	return (
		<li className="row">
			<DropdownCalendar
				opened={true}
				selection={timeslot.date ? timeslot.date : 'YYYY-MM-DD'}
				minimum={DateTime.now()}
				maximum={DateTime.now().plus({ months: organization.availability })}
				selected={timeslot.date}
				loading={loading}
				on_month_change={({ year, month }) => {
					year_set(year);
					month_set(month);
					new_booking_context.download_timeslots({ year, month });
				}}
				clb={(date) => {
					if (timeslot.date !== date) timeslot.time = undefined;
					timeslot.date = date;
					new_booking_context.selected_timeslots = [...new_booking_context.selected_timeslots];
					setTimeout(() => set_dropdown_open(true), 1);
				}}
				dates_cls={cls}
			/>
			<Dropdown
				cls="time"
				selection={timeslot.time ? pretty_interval(timeslot.time, timeslot.time + new_booking_context.duration) : '00:00 - 00:00'}
				selected={timeslot.time}
				list={time_list}
				clb={(time) => {
					timeslot.time = time;
					timeslot.rooms = timeslots_for_time.find(({ time }) => time === timeslot.time).rooms;
					new_booking_context.selected_timeslots = [...new_booking_context.selected_timeslots];
				}}
				disabled={!timeslot.date}
				open={dropdown_open}
				set_open={set_dropdown_open}
			/>

			<div className="addition">
				<div className="mobile_info">Date & Time {n + 1}</div>
				<a
					href="#!"
					className="btn_close"
					onClick={() => {
						let arr = [...new_booking_context.selected_timeslots];
						arr.splice(n, 1);
						new_booking_context.selected_timeslots = arr;
					}}
				></a>
			</div>
		</li>
	);
};

const BookingDatetimes = () => {
	const navigate = useNavigate();

	const new_booking_context = useContext(NewBookingContext);
	const user_context = useContext(UserContext);
	const modals_context = useContext(ModalsContext);

	const finished = () => {
		if (user_context.user) {
			navigate('/new_booking/details');
			return;
		} else {
			modals_context.show('authentication', {
				clb: () => {
					modals_context.hide();
					navigate('/new_booking/details');
				},
			});
		}
	};

	return (
		<div className="app2">
			<Headerbar page="/new_booking/datetimes" back_clb={() => navigate('/new_booking/rooms')} />
			<Stepper
				back_clb={() => navigate('/new_booking/rooms')}
				step_1_clb={() => navigate('/new_booking/rooms')}
				current_step={2}
				step_finished={new_booking_context.selected_timeslots_valid}
				step_3_clb={new_booking_context.selected_timeslots_valid ? finished : null}
			/>
			<div
				className={`dates_times ${new_booking_context.selected_timeslots_valid ? ' selected' : ''}${
					new_booking_context.selected_timeslots.length > 1 ? ' several' : ''
				}`}
			>
				<a className="btn_next mobile" href="#!" onClick={finished}>
					Next
				</a>

				<div className="sum">
					<h4>Rooms</h4>
					<ul className="rooms">
						{Object.entries(new_booking_context.selected_rooms).map(([_id, count]) => (
							<li key={_id}>
								<div>{new_booking_context.rooms.find((room) => room._id === _id).name}</div>
								<div>
									{count} room{count > 1 ? 's' : ''}
								</div>
							</li>
						))}
					</ul>
					<div className="output_wrap">
						<div className="duration">Duration: {pretty_period(new_booking_context.duration)}</div>
						<div className="total">Room Total: {new_booking_context.total_rooms}</div>
					</div>
				</div>

				<div className="add_options">
					<h4>Select Date & Time</h4>

					<ul className="dropdowns">
						{new_booking_context.selected_timeslots.map((timeslot, i) => (
							<Timeslot timeslot={timeslot} key={i} n={i} />
						))}
					</ul>

					<a
						className="btn_add"
						href="#!"
						onClick={() => {
							new_booking_context.selected_timeslots = [...new_booking_context.selected_timeslots, { date: undefined, time: undefined }];
						}}
					>
						+ Add another Date & Time
					</a>
					<a className="btn_next desktop" href="#" onClick={finished}>
						Next
					</a>
				</div>
			</div>
		</div>
	);
};

export default BookingDatetimes;
