import React, { useContext } from 'react';

import ModalsContext from '#context/modals.jsx';

const SavedChanges = () => {
	const modals_context = useContext(ModalsContext);
	const { state, text } = modals_context.data;

	return (
		<div className="popup">
			<div className="account_form saved_changes">
				<div className={`${state === 'column' ? 'column' : ''}`}>
					<span></span>
					<h3>Congratulations</h3>
				</div>
				{text ? <p dangerouslySetInnerHTML={{ __html: text }}></p> : <p>Your changes have been successfully saved!</p>}
				<button type="submit" onClick={modals_context.hide}>
					Continue
				</button>
			</div>
		</div>
	);
};

export default SavedChanges;
