import CONFIG from '../config.json';

export default async function (route, query = {}) {
	query = Array.from(Object.entries(query))
		.filter(([name, query]) => query !== undefined)
		.map(([name, query]) => `${name}=${query}`)
		.join('&');

	const response = await fetch(`${CONFIG.api}/${route}${query ? `?${query}` : ''}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	});

	let data;
	try {
		data = await response.json();
	} catch (e) {
		//JSON is empty, it can be, it's ok
	}

	if (response.ok) {
		return data;
	} else if (response.status === 400) {
		throw data;
	} else {
		throw 'api_error';
	}
}
