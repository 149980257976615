import React from 'react';
import { useNavigate } from 'react-router-dom';

import Headerbar from '#components/headerbar.jsx';

import ContactUs from '#modals/contact_us.jsx';

const CalendarPage = () => {
	const navigate = useNavigate();

	return (
		<div className="app2 contact_us">
			<Headerbar page="/contact_us" is_first_page={true} back_clb={() => navigate(-1)} />
			<ContactUs />
		</div>
	);
};
export default CalendarPage;
