import React, { useState } from 'react';
import Headerbar from '#components/headerbar.jsx';
import api_user from '#api/user.js';

import { useNavigate, useParams } from 'react-router-dom';

const _form_fields = ['password', 'confirm_password'];

const CreateAccount = (props) => {
	const nagivate = useNavigate();

	const { token } = useParams();

	let temp = {};
	let temp2 = {};
	_form_fields.forEach((field) => (temp[field] = ''));
	_form_fields.forEach((field) => (temp2[field] = ''));

	const [data, set_data] = useState(temp);
	const [errors, set_errors] = useState(temp2);

	const [finished, set_finished] = useState(false);

	const state = {
		data,
		set: (field, value) => {
			let new_data = { ...data, [field]: value };
			set_data(new_data);

			if (!value) state.set_error(field, 'This field is required');

			state.set_error('confirm_password', new_data.password !== new_data.confirm_password ? 'Please enter the same password' : '');
		},
		errors,
		set_error: (field, value) => {
			if (errors[field] === value) return;
			set_errors({ ...errors, [field]: value });
		},
		clear_errors: () => {
			let data = {};
			_form_fields.forEach((field) => (data[field] = ''));
			set_errors(data);
		},
	};

	const [password_visible, set_password_visible] = useState(false);

	const [loading, set_loading] = useState(false);

	let form_valid = !loading;

	_form_fields.forEach((field) => {
		if (!state.data[field]) form_valid = false;
		if (state.errors[field]) form_valid = false;
	});

	const on_submit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (!form_valid) return false;

		if (state.data.password !== state.data.confirm_password) {
			state.set_error('confirm_password', 'Please enter the same password');
			return false;
		}

		state.clear_errors();

		set_loading(true);

		(async () => {
			//TODO storage and newer version
			try {
				await api_user.set_password({
					password: state.data.password,
					token,
				});
				set_loading(false);
				set_finished(true);
			} catch (e) {
				set_loading(false);
				switch (e) {
					case 'token':
					case 'user':
					default:
						state.set_error('password', 'Invalid token. Please try to request password set again');
						break;
				}
			}
		})();

		return false;
	};

	return (
		<div className="app2">
			<Headerbar page="/set_password" />
			{finished ? (
				<div className="finish_container">
					<div className="finish">
						<div className="check">
							<div className="img"></div>
							<h3>You can sign in now</h3>
						</div>
						<p className="text">Your password was sucessfully set</p>
						<a className="btn_continue" href="#!" onClick={() => nagivate('/')}>
							Continue
						</a>
					</div>
				</div>
			) : (
				<form action="#" className="account_form" onSubmit={on_submit}>
					<h3>Set Password</h3>

					<div className={`input${state.errors.password ? ' with_error' : ''}`}>
						<label htmlFor="password">Password*</label>
						<input
							type={password_visible ? 'text' : 'password'}
							name="password"
							id="password"
							placeholder="&nbsp;"
							required
							value={state.data.password}
							onChange={(e) => state.set('password', e.target.value)}
						/>
						<span className="error">{state.errors.password}</span>
						<span className={`toggler${password_visible ? ' toggled' : ''}`} onClick={() => set_password_visible(!password_visible)} />
					</div>

					<div className={`input${state.errors.confirm_password ? ' with_error' : ''}`}>
						<label htmlFor="confirm_password">Confirm Password*</label>
						<input
							type={password_visible ? 'text' : 'password'}
							name="confirm_password"
							id="confirm_password"
							placeholder="&nbsp;"
							required
							value={state.data.confirm_password}
							onChange={(e) => state.set('confirm_password', e.target.value)}
						/>
						<span className="error">{state.errors.confirm_password}</span>
						<span className={`toggler${password_visible ? ' toggled' : ''}`} onClick={() => set_password_visible(!password_visible)} />
					</div>

					<button type="submit" disabled={!loading && form_valid ? '' : 'disabled'}>
						Set Password
					</button>
				</form>
			)}
		</div>
	);
};

export default CreateAccount;
