import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import api_user from '#api/user.js';

import Loader from '#components/loader.jsx';

import { DateTime } from 'luxon';

const UserContext = React.createContext();

const cookie_default = {
	path: '/',
};

function UserProvider(props) {
	const navigate = useNavigate();
	const [cookies, cookies_set, cookies_remove] = useCookies(['session']);

	const [remember_me, remember_me_set] = useState(cookies.remember_me ? cookies.remember_me === 'true' : true);
	const [session, session_set] = useState(cookies.session || undefined);
	const [user, user_set] = useState(undefined);

	const [state, set_state] = useState('');

	const log_out = () => {
		store.user = undefined;
		store.session = undefined;
		navigate('/');
	};

	const store = {
		get loaded() {
			return state === 'loaded';
		},
		set loaded(_) {},
		get email() {
			return cookies.email || '';
		},
		set email(v) {
			if (remember_me) {
				cookies_set('email', v, {
					...cookie_default,
					expires: remember_me ? DateTime.now().plus({ year: 1 }).toJSDate() : 0,
				});
			}
		},
		get user() {
			return user;
		},
		set user(v) {
			user_set(v);
			if (v) store.email = v.email;
		},
		get session() {
			return session;
		},
		set session(v) {
			session_set(v);

			if (v) {
				cookies_set('session', v, {
					...cookie_default,
					expires: remember_me ? DateTime.now().plus({ year: 1 }).toJSDate() : 0,
				});
			} else {
				cookies_remove('session', cookie_default);
			}
		},
		get remember_me() {
			return remember_me;
		},
		set remember_me(v) {
			remember_me_set(v);

			if (v) {
				cookies_set('remember_me', true, cookie_default);
			} else {
				cookies_set('remember_me', false, cookie_default);
				cookies_remove('email');
			}
		},
		log_out,
	};

	useEffect(() => {
		if (state === 'loading') return;
		if (!session) {
			set_state('loaded');
			return;
		}
		if (user) {
			set_state('loaded');
			return;
		}

		set_state('loading');

		(async () => {
			let res;
			try {
				res = await api_user.resume_session(session);
				if (res && res.user) {
					store.user = res.user;
					set_state('loaded');
				}
			} catch (e) {
				store.session = undefined;
				set_state('loaded');
			}
		})();
	}, [session]);

	return <UserContext.Provider value={store}>{props.children}</UserContext.Provider>;
}

function UserDeffer({ children, fallback }) {
	const context = useContext(UserContext);

	if (!context.user) {
		if (context.loaded) {
			localStorage.setItem('redirect', window.location.pathname + window.location.hash);
			window.location = '/sign_in';
			fallback();
			return <></>;
		}
		return <Loader />;
	}

	return children;
}

export default UserContext;
export { UserProvider, UserDeffer };
