import get from './get.js';
import fetch from './fetch.js';
import post from './post.js';

export default {
	get_profile: ({ session, user_id }) => get('users/profile', { session, user_id }),
	set_profile: ({ session, user_id, first_name, last_name, email, phone, photo, password }) => {
		const body = new FormData();
		body.append('session', session);
		if (user_id) body.append('user_id', user_id);
		body.append('first_name', first_name);
		body.append('last_name', last_name);
		body.append('email', email);
		body.append('phone', phone);
		if (photo === false || photo) body.append('photo', photo);
		if (password) body.append('password', password);

		return fetch('users/profile/', body);
	},
	list: ({ session, role, skip, limit }) => get('users/list', { session, role, skip, limit }),
	create: ({ session, email, first_name, last_name, role }) => post('users/create', { session, email, first_name, last_name, role }),
	delete: ({ session, user }) => post('users/delete', { session, user }),
	export: ({ session, role }) => get('users/export', { session, role }),
};
