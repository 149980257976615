import React, { useContext, useState, useEffect } from 'react';

import CustomInputsContext from '#context/custom_inputs.jsx';
import ModalsContext from '#context/modals.jsx';
import OrganizationContext from '#context/organization.jsx';
import UserContext from '#context/user.jsx';

import DropdownCalendar from '#components/dropdown_calendar.jsx';

import bookings_api from '#api/bookings.js';
import XLSX from 'xlsx';

import { DateTime } from 'luxon';

function generate_dates({ year, month }, date, week_hours, minimal) {
	date = DateTime.fromFormat(date, 'yyyy-MM-dd').toObject({ year: true, month: true, day: true });

	const dates = {};

	if (year === date.year && month === date.month) dates[date.day] = 'active';

	if (minimal) {
		const now = DateTime.fromFormat(minimal, 'yyyy-MM-dd').toObject({ year: true, month: true, day: true });

		if (year === now.year && month === now.month) {
			for (let i = 1; i < now.day; i++) dates[i] = 'inactive';
		}
	}

	const datetime = DateTime.fromObject({ year, month });

	for (let i = 1; i <= datetime.daysInMonth; i++) {
		const hours = week_hours[datetime.set({ day: i }).weekday];

		if (hours[0] === 0 && hours[1] === 0) dates[i] = 'unavailable';
	}

	return dates;
}

const CalendarExportXLSX = () => {
	const { session } = useContext(UserContext);
	const { organization } = useContext(OrganizationContext);
	const inputs_context = useContext(CustomInputsContext);
	const modals_context = useContext(ModalsContext);
	const { list, get_list } = inputs_context;

	const [from, set_from] = useState(modals_context.data.date || DateTime.now().toFormat('yyyy-MM-dd'));
	const [to, set_to] = useState(modals_context.data.date || DateTime.now().toFormat('yyyy-MM-dd'));

	const [dates_cls, set_dates_cls] = useState({});
	const [dates_cls2, set_dates_cls2] = useState({});

	useEffect(() => {
		get_list();
	}, []);

	const fields = [
		{
			id: 'blocked_time',
			name: 'Blocked Time',
			data: (booking) => (booking.blockedTime ? 'TRUE' : 'FALSE'),
		},
		{
			id: 'date',
			name: 'Date',
			data: (booking) => booking.schedule.date,
		},
		{
			id: 'email',
			name: 'Email',
			data: (booking) => booking.email,
		},
		{
			id: 'attendees',
			name: 'Attendees',
			data: (booking) => booking.requestInformation.Attendees,
		},
		{
			id: 'phone',
			name: 'Phone',
			data: (booking) => booking.requestInformation.phone,
		},
		{
			id: 'name',
			name: 'Name',
			data: (booking) => booking.requestInformation.name,
		},
		{
			id: 'rooms',
			name: 'Rooms',
			data: (booking) => {
				const rooms = [];
				Object.keys(booking.schedule.rooms).forEach((roomKey) => {
					Object.keys(booking.schedule.rooms[roomKey]).forEach((roomIndex) => {
						const room = booking.schedule.rooms[roomKey][roomIndex];
						rooms.push(`${room.roomType} ${room.name}`);
					});
				});

				return rooms.join(', ');
			},
		},
		{
			id: 'setup_start',
			name: 'Setup Start',
			data: (booking) => booking.schedule.times[0].start,
		},
		{
			id: 'setup_end',
			name: 'Setup End',
			data: (booking) => booking.schedule.times[0].end,
		},
		{
			id: 'session_start',
			name: 'Session Start',
			data: (booking) => booking.schedule.times[1].start,
		},
		{
			id: 'session_end',
			name: 'Session End',
			data: (booking) => booking.schedule.times[1].end,
		},
		{
			id: 'cleanup_start',
			name: 'Cleanup Start',
			data: (booking) => booking.schedule.times[2].start,
		},
		{
			id: 'cleanup_end',
			name: 'Cleanup End',
			data: (booking) => booking.schedule.times[2].end,
		},
		{
			id: 'status',
			name: 'Status',
			data: (booking) => booking.status,
		},
		{
			id: 'session_name',
			name: 'Session Name',
			data: (booking) => booking.requestInformation.session_name,
		},
		{
			id: '_id',
			name: 'UUID',
			data: (booking) => booking._id,
		},
		{
			id: 'details',
			name: 'Details',
			data: (booking) => booking.additionalDetails,
		},
		{
			id: 'notes',
			name: 'Notes',
			data: (booking) => booking.additionalNotes,
		},
	];

	const default_selection = {};
	fields.forEach((field) => (default_selection[field.id] = true));

	const [selected_fields, set_selected_fields] = useState(default_selection);

	const on_submit = async (e) => {
		e.preventDefault();

		const bookings = await bookings_api.export_xlsx({ session, from, to, fields: selected_fields });

		const data = [];

		const filtered_fields = fields.filter((field) => selected_fields[field.id]);
		const header = filtered_fields.map((field) => field.name);
		const filtered_custom_inputs = list.filter((input) => selected_fields[input.uuid]);

		header.push(...filtered_custom_inputs.map((input) => input.title));
		data.push(header);

		bookings.forEach((booking) => {
			if (booking.schedule === null || booking.schedule === undefined || booking.schedule.rooms === null || booking.schedule.rooms === undefined)
				return;

			const entry = filtered_fields.map((field) => field.data(booking) || '');
			filtered_custom_inputs.forEach((input) => {
				if (!booking.customValues) return entry.push('');
				const data = booking.customValues[input.uuid];
				if (!data) return entry.push('');
				if (data.source.type === 'Checkboxes') {
					let text = data.source.options.split('\n');
					if (data.value) text = text.map((line, i) => `${line} ${data.value[i] ? '🗹' : '☐'}`);
					entry.push(text.join('\n'));
				} else {
					entry.push(data.value);
				}
			});
			data.push(entry);
		});

		const ws = XLSX.utils.aoa_to_sheet(data);
		const wb = XLSX.utils.book_new();

		XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
		XLSX.writeFile(
			wb,
			`${organization.name || ''} - ${new Date().toLocaleString('en-us', { year: 'numeric', month: 'numeric', day: 'numeric' })}.xlsx`
		);

		modals_context.hide();
	};

	return (
		<form className="popup calendar_export" onSubmit={on_submit}>
			<a className="btn_close" href="#!" onClick={modals_context.hide}></a>
			<h3>Export to .xlsx file</h3>

			<div className="dates">
				<div className="date">
					<p>From:</p>
					<DropdownCalendar
						selected={from}
						selection={from}
						on_month_change={(current) => set_dates_cls(generate_dates(current, from, organization.hours))}
						dates_cls={dates_cls}
						clb={(current) => {
							set_from(current);
							if (DateTime.fromFormat(current, 'yyyy-MM-dd').toMillis() > DateTime.fromFormat(to, 'yyyy-MM-dd').toMillis()) {
								set_to(current);
							}
						}}
					/>
				</div>
				<div className="date">
					<p>To:</p>
					<DropdownCalendar
						selected={to}
						selection={to}
						minimum={DateTime.fromFormat(from, 'yyyy-MM-dd').toObject({ year: true, month: true })}
						on_month_change={(current) => set_dates_cls2(generate_dates(current, to, organization.hours, from))}
						dates_cls={dates_cls2}
						clb={(current) => set_to(current)}
					/>
				</div>
			</div>

			<div className="fields_wrapper">
				<p>Fields</p>
				<div className="fields">
					<div className="columns">
						{fields.map((field, i) => (
							<div className="checkbox" key={i}>
								<input
									type="checkbox"
									id={field.id}
									name={field.id}
									checked={!!selected_fields[field.id]}
									onChange={(e) => set_selected_fields({ ...selected_fields, [field.id]: e.target.checked })}
								/>
								<label htmlFor={field.id}>{field.name}</label>
							</div>
						))}
						{(list || [])
							.filter((field) => field.type !== 'Note' && field.type !== 'Divider')
							.map((field, i) => (
								<div className="checkbox" key={i}>
									<input
										type="checkbox"
										id={field.uuid}
										name={field.uuid}
										checked={!!selected_fields[field.uuid]}
										onChange={(e) => set_selected_fields({ ...selected_fields, [field.uuid]: e.target.checked })}
									/>
									<label htmlFor={field.uuid}>
										{field.title ? '' : field.type + ' '}
										{field.title}
									</label>
								</div>
							))}
					</div>
				</div>
			</div>

			<button type="submit">Export</button>
		</form>
	);
};

export default CalendarExportXLSX;
