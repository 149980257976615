import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import NewBookingContext from '#context/new_booking.jsx';

const BookingFinished = () => {
	const navigate = useNavigate();
	const new_booking_context = useContext(NewBookingContext);

	const [is_plural] = useState(new_booking_context.selected_timeslots.length > 1);

	useEffect(() => {
		new_booking_context.reset();
	}, []);

	return (
		<div className="app2">
			<div className="finish_container">
				<div className="finish">
					<div className="check">
						<div className="img"></div>
						<h3>{`Booking${is_plural ? 's' : ''} Request Received`}</h3>
					</div>
					<p className="text">Once processed you will receive a confirmation email</p>
					<a className="btn_continue" href="#!" onClick={() => navigate('/')}>
						Continue
					</a>
				</div>
			</div>
		</div>
	);
};

export default BookingFinished;
