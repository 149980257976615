import React, { useContext } from 'react';

import ModalsContext from '#context/modals.jsx';

import { pretty_period } from '#components/time_helpers.js';

const Popup = () => {
	const modals_context = useContext(ModalsContext);

	const { name, capacity, max_duration, setup, cleanup, description, photo } = modals_context.data;

	return (
		<div className="popup room">
			<a className="btn_close" href="#!" onClick={modals_context.hide}></a>
			<h3>{name}</h3>
			<div className="content">
				{photo ? <img src={photo} alt="room" /> : <></>}
				<div className="duration">
					Maximum Duration: {max_duration} Hour{max_duration > 1 ? 's' : ''}
				</div>
				{description ? <p className="text">{description}</p> : <></>}
				{setup || cleanup ? (
					<div>
						{setup ? <div className="setup">Setup time: {pretty_period(setup)}</div> : <></>}
						{cleanup ? <div className="cleanup">Cleanup time: {pretty_period(cleanup)}</div> : <></>}
					</div>
				) : (
					<></>
				)}
			</div>
			<a className="btn_back" href="#!" onClick={modals_context.hide}>
				Back
			</a>
		</div>
	);
};

export default Popup;
