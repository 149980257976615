import React, { useState, useContext } from 'react';

import api_assets from '#api/assets.js';

import UserContext from '#context/user.jsx';

const AssetsContext = React.createContext();

const _fields_to_update = ['name', '_id', 'type', 'ids'];

function AssetsProvider(props) {
	const { session } = useContext(UserContext);
	const [list, set_list] = useState(undefined);
	const [asset, set_asset] = useState(undefined);

	const get = async (asset) => {
		set_asset(undefined);
		const data = await api_assets.get({ session, asset });
		set_asset(data);
		return data;
	};

	const update = async (data) => {
		const result = await api_assets.update({ session, asset: data._id, ...data });
		set_asset(result);

		const list_entry = list.find(({ _id }) => _id === result._id);
		if (list_entry) {
			_fields_to_update.forEach((field) => (list_entry[field] = result[field]));
			set_list([...list]);
		}
	};

	const create = async (data) => {
		const result = await api_assets.create({ session, ...data });
		set_asset(result);

		const list_entry = {};
		_fields_to_update.map((field) => (list_entry[field] = result[field]));
		set_list([...list, list_entry]);
	};

	const get_list = async () => {
		set_list(undefined);
		set_list(await api_assets.list_all(session));
	};

	const get_list_for_booking = async (booking) => {
		return await api_assets.list_for_booking(booking);
	};

	const get_list_for_booking_editing = async (booking) => {
		return await api_assets.list_for_booking_editing({ session, booking });
	};

	const _delete = async (asset) => {
		const index = list.findIndex((item) => item._id === asset);
		if (index === -1) {
			return;
		}

		await api_assets.delete({ session, asset });
		const new_list = [...list];
		new_list.splice(index, 1);
		set_list(new_list);
	};

	const update_indexes = async (data) => {
		const indexes = data.map((asset, index) => ({
			asset: asset._id,
			index,
		}));
		const new_list = [...list];
		indexes.forEach((asset_data) => {
			const asset = new_list.find((asset) => asset._id === asset_data.asset);
			if (asset) asset.index = asset_data.index;
		});
		set_list(new_list);
		await api_assets.update_indexes({ session, indexes });
	};

	const store = {
		get asset() {
			return asset;
		},
		set asset(_) {},
		get list() {
			return list;
		},
		set list(_) {},
		get_list,
		get_list_for_booking,
		get_list_for_booking_editing,
		get,
		api: {
			create,
			update,
			delete: _delete,
			update_indexes,
		},
	};

	return <AssetsContext.Provider value={store}>{props.children}</AssetsContext.Provider>;
}

export default AssetsContext;
export { AssetsProvider };
