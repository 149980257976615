import post from './post.js';
import get from './get.js';

export default {
	list: ({ session, unseen, skip, limit }) => get('notifications/list', { session, unseen, skip, limit }),
	count_unseen: ({ session }) => get('notifications/count_unseen', { session }),
	count_unseen_bookings: ({ session }) => get('notifications/count_unseen_bookings', { session }),
	seen: ({ session, notification, seen }) => post('notifications/seen', { session, notification, seen }),
	seen_all: ({ session, booking }) => post('notifications/seen_all', { session, booking }),
	delete: ({ session, notification }) => post('notifications/delete', { session, notification }),
};
