import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Headerbar from '#components/headerbar.jsx';
import ModalsContext from '#context/modals.jsx';
import SignIn from '#components/sign_in_form.jsx';

const Login = () => {
	const navigate = useNavigate();
	const modals_context = useContext(ModalsContext);

	return (
		<div className="app2">
			<Headerbar page="/sign_in" />
			<SignIn
				submit_callback={() => {
					let path = localStorage.getItem('redirect');
					if (path) {
						localStorage.removeItem('redirect');
					} else {
						path = '/';
					}
					navigate(path);
				}}
				forgot_password_callback={(email) => {
					modals_context.show('forgot_password', { initial_email: email });
				}}
				create_account_callback={() => navigate('/create_account')}
			/>
		</div>
	);
};

export default Login;
