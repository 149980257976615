import React, { useState, useContext } from 'react';

import api_notifications from '#api/notifications.js';

import UserContext from '#context/user.jsx';

const NotificationContext = React.createContext();

function NotificationsProvider(props) {
	const { session } = useContext(UserContext);

	const [unseen_notifications, unseen_notifications_set] = useState(0);
	const [unseen_bookings, unseen_bookings_set] = useState(0);
	let [list, list_set] = useState(false);

	const get_list = async (data, duplicate = false) => {
		const response = await api_notifications.list({ session, ...data });
		let new_notifications = new Map((store.list || []).filter((el) => !el.deleted).map((el) => [el._id, el]));

		if (!duplicate)
			response.notifications.forEach((el) => {
				if (new_notifications.has(el._id)) {
					if (!duplicate) {
						get_list({ ...data, skip: 0, limit: list.length }, true);
						duplicate = true;
					}
				}
				new_notifications.set(el._id, el);
			});
		new_notifications = Array.from(new_notifications.values()).sort((a, b) => b.timestamp - a.timestamp);

		list_set(new_notifications);

		unseen_notifications_set(response.unseen_notifications);
		unseen_bookings_set(response.unseen_bookings);

		return new_notifications.length < response.total;
	};

	const clear_list = async (data) => {
		list_set(undefined);
		list = undefined; //No idea why it doesn't work without it
		return await get_list(data);
	};

	const store = {
		get unseen_notifications() {
			return unseen_notifications;
		},
		set unseen_notifications(v) {
			if (unseen_notifications !== v) store._update_unseen_bookings();
			unseen_notifications_set(v);
		},
		unseen_notifications_set(v) {
			unseen_notifications_set(v);
		},
		get unseen_bookings() {
			return unseen_bookings;
		},
		set unseen_bookings(v) {
			unseen_bookings_set(v);
		},
		async update_counters() {
			let data = await api_notifications.count_unseen({ session });
			unseen_notifications_set(data.unseen_notifications);
			unseen_bookings_set(data.unseen_bookings);
		},
		async _update_unseen_bookings() {
			let data = await api_notifications.count_unseen_bookings({ session });
			unseen_bookings_set(data.unseen_bookings);
		},
		get list() {
			return list;
		},
		set list(_) {},
		get_list,
		clear_list,
		api: {
			delete: async (notification) => {
				await api_notifications.delete({ session, notification });

				if (list) {
					let entry = list.find(({ _id }) => notification === _id);
					if (entry) {
						list_set(list.filter((entry1) => entry !== entry1));
						if (!entry.seen) store.unseen_notifications -= 1;
					}
				}
			},
			seen: async (notification, seen) => {
				await api_notifications.seen({ session, notification, seen });
				store.unseen_notifications += seen ? -1 : 1;
				if (list) {
					list.forEach((entry) => {
						if (entry._id !== notification) return;
						entry.seen = seen;
					});

					list_set([...list]);
				}
			},
			seen_all: async (booking) => {
				await api_notifications.seen_all({ session, booking });
				if (list) {
					list.filter((notification) => notification.booking._id === booking).forEach((notificaiton) => (notificaiton.seen = true));
					list_set([...list]);
				}
				store.update_counters();
			},
		},
	};

	return <NotificationContext.Provider value={store}>{props.children}</NotificationContext.Provider>;
}

export default NotificationContext;
export { NotificationsProvider };
