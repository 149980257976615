import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import UserContext from '#context/user.jsx';
import UsersContext from '#context/users.jsx';
import Loader from '#components/loader.jsx';

const ProfileInfo = ({ data }) => {
	const navigate = useNavigate();
	const user_context = useContext(UserContext);
	const users_context = useContext(UsersContext);

	const [user, set_user] = useState();
	const [selected_file, set_selected_file] = useState();
	const [errors, set_errors] = useState({});
	const [show_password, set_show_password] = useState(false);
	const [preview, set_preview] = useState('');

	useEffect(() => {
		if (!data[0]) data[0] = user_context.user._id;

		set_user();
		(async () => {
			const user = await users_context.get(data[0]);
			set_user({ ...user, password: '' });
			set_selected_file(user?.photo);
		})();
	}, [data[0]]);

	useEffect(() => {
		set_preview(user?.photo);
	}, [user?.photo]);

	const validate_form = () => {
		const errors = {};

		if (user.password && user.password !== user.confirm_password) {
			errors.confirm_password = 'Passwords don’t match';
		}

		set_errors(errors);
		return Object.keys(errors).length === 0;
	};

	const handle_change = (e) => {
		const { name, value } = e.target;
		set_user((user) => ({ ...user, [name]: value }));
		set_selected_file(user.photo || '');
	};

	const handle_submit = async (e) => {
		e.preventDefault();

		if (!validate_form()) return;

		user.user_id = user._id;
		delete user._id;
		delete user.confirm_password;
		if (!user.password) delete user.password;

		if (selected_file === false || (selected_file && selected_file !== user.photo)) {
			user.photo = selected_file;
		} else {
			delete user.photo;
		}

		try {
			await users_context.set(user);
		} catch (e) {
			console.error(e);
		}
		navigate('#');
	};

	const handle_reset = (e) => {
		e.preventDefault();
		set_user(null);
		navigate('#');
	};

	const toggle_password = (event) => {
		event.preventDefault();
		set_show_password((show_password) => !show_password);
	};

	if (!user) return <Loader />;

	return (
		<div className="profile_info">
			<div className="header_block">
				<NavLink className="icon border close" to="#"></NavLink>
				<h2>Profile Info</h2>
			</div>

			<form onSubmit={handle_submit} autoComplete="off">
				<div className="input">
					<label htmlFor="first_name">First Name*</label>
					<input type="text" value={user.first_name} onChange={handle_change} name="first_name" required />
				</div>

				<div className="input">
					<label htmlFor="last_name">Last Name*</label>
					<input type="text" value={user.last_name} onChange={handle_change} name="last_name" required />
				</div>

				<div className="input">
					<label htmlFor="email">Email*</label>
					<input type="email" value={user.email} onChange={handle_change} name="email" required />
				</div>

				<div className="input">
					<label htmlFor="phone">Phone Number</label>
					<input type="tel" value={user.phone} onChange={handle_change} name="phone" />
				</div>

				<div className={`photo with${preview ? '' : 'out'}_photo`}>
					<label>Profile Photo</label>
					<label className="input">
						<input
							type="file"
							onChange={(event) => {
								const file = event.target.files[0];
								set_selected_file(file);

								const reader = new FileReader();
								reader.onload = () => {
									const fileContent = reader.result;
									set_preview(fileContent);
								};
								reader.readAsDataURL(file);
							}}
						/>
						<div className="image">
							{preview ? (
								<img className="preview" width="80px" height="80px" src={preview} alt="Preview" />
							) : (
								<span className="initials">{`${user.first_name[0] || ''}${user.last_name[0] || ''}`}</span>
							)}
							<span className="action">+</span>
						</div>
						{preview && (
							<a
								onClick={(event) => {
									event.preventDefault();
									set_selected_file(false);
									set_preview('');
								}}
							></a>
						)}
					</label>
				</div>

				{user_context.user._id === user._id ? (
					<>
						<label htmlFor="password">Change Password</label>
						<div className="change_password">
							<div className={`input ${errors.confirm_password ? 'with_error' : ''}`}>
								<input
									autoComplete=""
									type={show_password ? 'text' : 'password'}
									onChange={handle_change}
									value={user.password}
									placeholder="New Password"
									name="password"
								/>
								<span className={`toggler ${show_password ? 'toggled' : ''}`} onClick={toggle_password} />
							</div>

							<div className={`input ${errors.confirm_password ? 'with_error' : ''}`}>
								<input
									type={show_password ? 'text' : 'password'}
									onChange={handle_change}
									value={user.confirm_password}
									placeholder="Confirm Password"
									name="confirm_password"
								/>
								<span className={`toggler ${show_password ? 'toggled' : ''}`} onClick={toggle_password} />
								{errors.confirm_password && <span className="error">{errors.confirm_password}</span>}
							</div>
						</div>
					</>
				) : (
					<></>
				)}

				<div className="actions">
					<button type="reset" onClick={handle_reset}>
						Cancel
					</button>
					<button type="submit">Save</button>
				</div>
			</form>
		</div>
	);
};

export default ProfileInfo;
