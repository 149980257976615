import React, { useState, useContext } from 'react';

import api_custom_inputs from '#api/custom_inputs.js';

import UserContext from '#context/user.jsx';

const CustomInputsContext = React.createContext();

function CustomInputsProvider(props) {
	const { session } = useContext(UserContext);
	const [list, set_list] = useState(undefined);
	const [input, set_input] = useState(undefined);

	const update = async (data) => {
		const result = await api_custom_inputs.update({ session, custom_input: data._id, ...data });

		const index = list.findIndex(({ _id }) => _id === result._id);
		if (index === -1) {
			return;
		}
		const new_list = [...list];
		new_list[index] = result;
		set_list(new_list);
	};

	const create = async (data) => {
		const result = await api_custom_inputs.create({ session, ...data });
		set_list([...list, result]);
	};

	const get_list = async () => {
		set_list(undefined);
		set_list(await api_custom_inputs.list());
	};

	const _delete = async (custom_input) => {
		const index = list.findIndex((item) => item._id === custom_input);
		if (index === -1) {
			return;
		}

		await api_custom_inputs.delete({ session, custom_input });
		const new_list = [...list];
		new_list.splice(index, 1);
		set_list(new_list);
	};

	const update_indexes = async (data) => {
		set_list(data);
		const indexes = data.map((item, index) => ({
			custom_input: item._id,
			index,
		}));
		await api_custom_inputs.update_indexes({ session, indexes });
	};

	const store = {
		get input() {
			return input;
		},
		set input(_) {},
		get list() {
			return list;
		},
		set list(_) {},
		get_list,
		set: set_input,
		api: {
			create,
			update,
			update_indexes,
			delete: _delete,
		},
	};

	return <CustomInputsContext.Provider value={store}>{props.children}</CustomInputsContext.Provider>;
}

export default CustomInputsContext;
export { CustomInputsProvider };
