import { useContext } from 'react';
import ModalsContext from '#context/modals';

const CustomInputDelete = ({ clb, text }) => {
	const modals_context = useContext(ModalsContext);

	return (
		<div className="popup">
			<div className="account_form booking">
				<h3>Delete Custom Field?</h3>
				{text ? <p dangerouslySetInnerHTML={{ __html: text }}></p> : <></>}

				<div className="actions">
					<button
						onClick={(e) => {
							e.preventDefault();
							modals_context.hide();
						}}
					>
						No
					</button>

					<button
						type="submit"
						onClick={() => {
							clb();
							modals_context.hide();
						}}
					>
						Yes
					</button>
				</div>
			</div>
		</div>
	);
};

export default CustomInputDelete;
