import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import WYSIWYGTextArea from '#components/wysiwyg_textarea.jsx';

import CustomInputsContext from '#context/custom_inputs';
import Loader from '#components/loader.jsx';

const ConfigCheckboxes = ({ input, on_change }) => (
	<div className="checkboxes">
		<div className="checkbox">
			<input type="checkbox" id="required" name="required" checked={input.required} onChange={on_change} />
			<label htmlFor="required">Required Field</label>
		</div>

		<div className="checkbox">
			<input type="checkbox" id="display" name="display" checked={input.display} onChange={on_change} />
			<label htmlFor="display">Display Field Name</label>
		</div>
	</div>
);

const Radio = ({ input, handle_change, handle_change_checked, set }) => {
	const [options, set_options] = useState(input.options?.split('\n') || []);

	const handle_change_option = (e, i) => {
		const new_options = [...options];
		new_options[i] = e.target.value;
		set_options(new_options);
	};

	useEffect(() => {
		set({ ...input, options: options.join('\n') });
	}, [options]);

	return (
		<>
			<div className="input">
				<label htmlFor="title">Field Name</label>
				<input type="text" name="title" value={input.title} onChange={handle_change} required />
			</div>

			<div className="input">
				<label htmlFor="option">Options</label>
				{options.map((option, i) => (
					<div>
						<input type="text" name="option" value={option} onChange={(e) => handle_change_option(e, i)} />
						<a
							className="icon"
							onClick={() => {
								const new_options = [...options];
								new_options.splice(i, 1);
								set_options(new_options);
							}}
						/>
					</div>
				))}
				<a onClick={() => set_options([...options, ''])}>+ Add option</a>
			</div>

			<ConfigCheckboxes input={input} on_change={handle_change_checked} />
		</>
	);
};

const Checkboxes = ({ input, handle_change, handle_change_checked, set }) => {
	const [options, set_options] = useState(input.options?.split('\n') || []);

	const handle_change_option = (e, i) => {
		const new_options = [...options];
		new_options[i] = e.target.value;
		set_options(new_options);
	};

	useEffect(() => {
		set({ ...input, options: options.join('\n') });
	}, [options]);

	return (
		<>
			<div className="input">
				<label htmlFor="title">Field Name</label>
				<input type="text" name="title" value={input.title} onChange={handle_change} required />
			</div>

			<div className="input">
				<label htmlFor="option">Options</label>
				{options.map((option, i) => (
					<div>
						<input type="text" name="option" value={option} onChange={(e) => handle_change_option(e, i)} />
						<a
							className="icon"
							onClick={() => {
								const new_options = [...options];
								new_options.splice(i, 1);
								set_options(new_options);
							}}
						/>
					</div>
				))}
				<a onClick={() => set_options([...options, ''])}>+ Add option</a>
			</div>

			<ConfigCheckboxes input={input} on_change={handle_change_checked} />
		</>
	);
};

const Checkbox = ({ input, handle_change, handle_change_checked }) => (
	<>
		<div className="input">
			<label htmlFor="title">Field Name</label>
			<input type="text" name="title" value={input.title} onChange={handle_change} required />
		</div>

		<ConfigCheckboxes input={input} on_change={handle_change_checked} />
	</>
);

const Note = ({ input, handle_change }) => (
	<div className="input_wysiwyg">
		<label htmlFor="note">Note Text</label>
		<WYSIWYGTextArea name="title" value={input.title} onChange={handle_change} required />
	</div>
);

const TextField = ({ input, handle_change, handle_change_checked }) => (
	<>
		<div className="input">
			<label htmlFor="title">Field Name</label>
			<input type="text" name="title" value={input.title} onChange={handle_change} required />
		</div>

		<div className="input">
			<label htmlFor="placeholder">Placeholder Text to show when field is empty</label>
			<input type="text" name="placeholder" value={input.placeholder} onChange={handle_change} />
		</div>

		<ConfigCheckboxes input={input} on_change={handle_change_checked} />
	</>
);

const _types = new Map([
	['Text Field Input', TextField],
	['Long Text Field Input', TextField],
	['Number Input', TextField],
	['Note', Note],
	['Radio', Radio],
	['Checkbox', Checkbox],
	['Checkboxes', Checkboxes],
	['Divider', null],
]);

const CustomInput = ({ data }) => {
	const navigate = useNavigate();
	const input_context = useContext(CustomInputsContext);
	const { input, set, list } = input_context;

	useEffect(() => {
		if (!list) return;

		if (!input) {
			const item = list.find((el) => el._id === data[0]);
			set(item);
		}
	}, [data[0], list]);

	if (!list || !input) return <Loader />;

	if (input?.type === 'Divider') return <></>;

	const Component = _types.get(input?.type);

	const handle_change = (e) => {
		let { name, value } = e.target;
		set((input) => ({ ...input, [name]: value }));
	};

	const handle_change_checked = (e) => {
		const { name, checked } = e.target;
		set((input) => ({ ...input, [name]: checked }));
	};

	const handle_submit = (e) => {
		e.preventDefault();

		try {
			!!data[0] ? input_context.api.update(input) : input_context.api.create(input);
			navigate('#');
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className={`form_item ${input.type === 'Radio' || input.type === 'Checkboxes' ? 'radio_field' : ''}`}>
			<div className="header_block">
				<a className="icon border close" href="#"></a>
				<h2>Create {input.type === 'Radio' ? 'Radio Button' : input.type.replace('Input', '')}</h2>
			</div>
			<form onSubmit={handle_submit}>
				<Component input={input} set={set} handle_change={handle_change} handle_change_checked={handle_change_checked} />

				<div className="actions">
					<button type="reset" onClick={() => navigate('#')}>
						Cancel
					</button>
					<button type="submit">Save</button>
				</div>
			</form>
		</div>
	);
};

export default CustomInput;
