import { useParams } from 'react-router-dom';
import BookingRequest from '#right_sections/booking_request';

const BookingView = () => {
	const { _id } = useParams();

	return (
		<div className="dashboard client">
			<BookingRequest data={[_id]} />
		</div>
	);
};

export default BookingView;
