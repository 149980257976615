import React, { useState, useContext } from 'react';
import ModalsContext from '#context/modals.jsx';

const validate_fields = (data) => {
	let errors = {};

	if (!data.email) {
		errors.email = 'Please enter email';
	}
	if (!data.first_name) {
		errors.first_name = 'Please enter first name';
	}
	if (!data.last_name) {
		errors.last_name = 'Please enter last name';
	}

	return errors;
};

const input_fields = [
	{ name: 'email', placeholder: 'Enter email' },
	{ name: 'first_name', placeholder: 'Enter first name' },
	{ name: 'last_name', placeholder: 'Enter last name' },
];

const Inputs = ({ data, handle_change, errors }) => (
	<>
		{input_fields.map((field) => (
			<div className={`input ${errors[field.name] ? 'with_error' : ''}`} key={field.name}>
				<input
					type={`${field.name === 'email' ? 'email' : 'text'}`}
					name={field.name}
					placeholder={field.placeholder}
					value={data[field.name]}
					onChange={handle_change}
				/>
				{errors[field.name] && <span className="error">{errors[field.name]}</span>}
			</div>
		))}
	</>
);

const UserAction = ({ action: def_action, clb, text }) => {
	const modals_context = useContext(ModalsContext);
	const [action, set_action] = useState(def_action);
	const [data, set_data] = useState({ role: action });
	const [errors, set_errors] = useState({});

	const handle_change = (e) => {
		set_data({ ...data, [e.target.name]: e.target.value });
		set_errors({ ...errors, [e.target.name]: '' });
	};

	const on_submit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		try {
			if (action === 'revoke') {
				clb();
				modals_context.hide();
			} else {
				const validation_errors = validate_fields(data);

				if (!Object.keys(validation_errors).length) {
					clb(data);
					set_action('success');
				} else {
					set_errors(validation_errors);
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	const renderContent = () => {
		switch (action) {
			case 'revoke':
				return (
					<div className="account_form user_action">
						<h3>Revoke Access?</h3>
						<p>
							Are you sure you want to revoke access from <span>{text}</span>? You can’t undo this action.
						</p>
						<div className="actions">
							<button onClick={modals_context.hide}>No</button>
							<button type="submit" onClick={on_submit}>
								Yes
							</button>
						</div>
					</div>
				);
			case 'admin':
				return (
					<form className="account_form user_action" onSubmit={on_submit}>
						<h3>Add Admin User</h3>
						<div className="content">
							<p className="notation">
								Admin users have full control over every setting. Looking to add staff? <a onClick={() => set_action('staff')}>Switch to staff</a>
							</p>
							<Inputs data={data} handle_change={handle_change} errors={errors} />
						</div>

						<div className="actions">
							<button
								onClick={(e) => {
									e.preventDefault();
									modals_context.hide();
								}}
							>
								Cancel
							</button>

							<button type="submit">Send Invite</button>
						</div>
					</form>
				);
			case 'staff':
				return (
					<form className="account_form user_action" onSubmit={on_submit}>
						<h3>Add Staff Member</h3>
						<div className="content">
							<Inputs data={data} handle_change={handle_change} errors={errors} />
						</div>

						<div className="actions">
							<button
								onClick={(e) => {
									e.preventDefault();
									modals_context.hide();
								}}
							>
								Cancel
							</button>

							<button type="submit">Send Invite</button>
						</div>
					</form>
				);
			case 'success':
				return (
					<div className="account_form user_action">
						<div className="title">
							<span></span>
							<h3>Congratulations</h3>
						</div>
						<p>Invite has been sent successfully</p>
						<button type="submit" onClick={modals_context.hide}>
							Continue
						</button>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div className={`popup ${action === 'admin' || action === 'staff' ? 'added_user' : ''} ${action === 'success' ? 'user_success' : ''}`}>
			{renderContent()}
		</div>
	);
};

export default UserAction;
