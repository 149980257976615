import React, { useState, useRef, useEffect } from 'react';

function DropdownElement({ chosen, view, value, clb }) {
	return (
		<div className={`item${chosen ? ' chosen' : ''}`} key={value} onClick={() => clb(value)}>
			{view}
		</div>
	);
}

function Dropdown({ cls = '', selection, selected, list = [], clb, disabled, open, set_open }) {
	if (!set_open) [open, set_open] = useState(false);

	const ref = useRef();

	const click_outside = (e) => {
		if (ref.current && !ref.current.contains(e.target)) set_open(false);
	};

	useEffect(() => {
		document.addEventListener('click', click_outside);
		return () => {
			document.removeEventListener('click', click_outside);
		};
	}, []);

	return (
		<div className={`dropdown${cls ? ` ${cls}` : ''}${open ? ' open' : ''}${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`} ref={ref}>
			<div className="selection" onClick={() => set_open(list.length ? !open : false)}>
				{selection}
			</div>
			<div className="list">
				{list.map(({ view, value, chosen }) => (
					<DropdownElement
						key={value}
						view={view}
						value={value}
						chosen={chosen}
						clb={(value) => {
							set_open(false);
							clb(value);
						}}
					/>
				))}
			</div>
		</div>
	);
}

export default Dropdown;
