import React, { useContext } from 'react';

import { full_date } from '#components/time_helpers.js';

import NotificationsContenxt from '#context/notifications.jsx';
import BookingsContext from '#context/bookings.jsx';

import { get_pretty_action } from '#utils/index.jsx';

function NotificationEntry({ notification }) {
	const bookings_context = useContext(BookingsContext);

	const { seen, action, user, timestamp } = notification;
	const notifications_context = useContext(NotificationsContenxt);

	const toggle_seen = async () => {
		try {
			await notifications_context.api.seen(notification._id, !seen);
			bookings_context.api.notification_seen(notification._id, !seen);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<li className={seen ? '' : 'unseen'}>
			<div className="info">
				<p className="subinfo">{full_date(timestamp)}</p>
				<p>{get_pretty_action(action, user)}</p>
			</div>
			<div className="actions">
				<a className={`icon ${seen ? 'seen' : 'unseen'}`} onClick={toggle_seen}></a>
			</div>
		</li>
	);
}

export default NotificationEntry;
