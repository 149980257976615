import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { prettier } from '#components/time_helpers.js';
import ModalsContext from '#context/modals.jsx';
import DropdownCalendar from '#components/dropdown_calendar.jsx';

import UserContext from '#context/user.jsx';
import OrganizationContext from '#context/organization.jsx';
import BookingsContext from '#context/bookings.jsx';

import Loader from '#components/loader.jsx';

import rooms_api from '#api/rooms.js';

import { DateTime } from 'luxon';

const Footer = ({ date }) => {
	const modals_context = useContext(ModalsContext);

	return (
		<div className="footer">
			<ul>
				<li>
					<div className="session"></div>Session
				</li>
				<li>
					<div className="setup"></div>Setup / Cleanup
				</li>
				<li>
					<div className="pending"></div>Pending
				</li>
				<li>
					<div className="blocked"></div>Blocked (by Admin via Block Time)
				</li>
			</ul>
			<div className="exports">
				<p>Export Calendar:</p>
				<div className="actions">
					<button onClick={() => modals_context.show('calendar_export_ics', { date })}>.ics</button>
					<button onClick={() => modals_context.show('calendar_export_xlsx', { date })}>.xlsx</button>
				</div>
			</div>
		</div>
	);
};

const RoomName = ({ room }) => {
	return (
		<div className="block">
			<h2>{room.type}</h2>
			{room.rooms.map((el, i) => (
				<div key={i} className="schedule_box">
					<h3>{el.name}</h3>
				</div>
			))}
		</div>
	);
};

const _schedule_class_and_action = (room, time, list, current, hovered) => {
	const booking = list.find((booking) => {
		let f = false;
		Object.values(booking.schedule.rooms).forEach((arr) =>
			arr.forEach((room_desc) => {
				if (room_desc.uuid === room.uuid) f = true;
			})
		);

		if (f) return time >= booking.schedule.times[0].start && time < booking.schedule.times[2].end;
	});

	if (!booking) return { cls: '' };

	let subclass = '';
	if (booking._id === current) {
		subclass = 'selected ';
	} else if (booking._id === hovered) {
		subclass = 'hovered ';
	}

	if (booking.status === 'pending') return { cls: `${subclass}pending`, _id: booking._id };

	if (booking.blockedTime) return { cls: `${subclass}blocked`, _id: booking._id };

	if (time < booking.schedule.times[0].end) return { cls: `${subclass}setup`, _id: booking._id };
	if (time >= booking.schedule.times[1].end) return { cls: `${subclass}setup`, _id: booking._id };

	return { cls: `${subclass}session`, _id: booking._id };
};

const RoomsSchedule = ({ room, time, list, hovered, set_hovered }) => {
	const location = useLocation();

	const data = location.hash.split('_');

	let current;

	if (data[0] === '#booking') current = data[1];

	return (
		<div className="block cells">
			<div className="table">
				{room.rooms.map((room, i) => {
					return (
						<div key={i} className="schedule_box">
							<div className="schedule">
								{time.map((time) => {
									const { cls, _id } = _schedule_class_and_action(room, time, list, current, hovered);

									if (_id) {
										return (
											<a
												href={`#booking_${_id}`}
												key={time}
												className={`cell ${cls}`}
												onMouseOver={() => set_hovered(_id)}
												onMouseOut={() => set_hovered(undefined)}
											></a>
										);
									} else {
										return <div key={time} className={`cell ${cls}`}></div>;
									}
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const Timeline = ({ time }) => {
	return (
		<div className="timeline">
			{time.map((time, i) => (
				<div key={i}>{prettier(time).toUpperCase()}</div>
			))}
		</div>
	);
};

const Main = ({ date }) => {
	const { session } = useContext(UserContext);
	const { calendar_list, get_calendar } = useContext(BookingsContext);
	const [rooms, set_rooms] = useState(undefined);
	const { organization } = useContext(OrganizationContext);
	const [hovered, set_hovered] = useState(undefined);

	const ref = useRef();

	useEffect(() => {
		(async () => {
			const all_rooms = await rooms_api.list_all({ session });

			let rooms = new Map();

			all_rooms.forEach((room) => {
				if (!rooms.has(room.roomType)) rooms.set(room.roomType, []);
				rooms.get(room.roomType).push(room);
			});

			set_rooms([...rooms.entries()].map(([type, rooms]) => ({ type, rooms })));
		})();
	}, [1]);

	useEffect(() => {
		get_calendar(date);
	}, [date]);

	if (!rooms || !calendar_list) return <Loader />;

	const hours = organization.hours[DateTime.fromFormat(date, 'yyyy-MM-dd').weekday];
	const time = [];
	for (let i = hours[0]; i < hours[1]; i += 0.5) time.push(i);

	return (
		<>
			<div className="main">
				<div className="names">
					{rooms.map((room, i) => (
						<RoomName room={room} key={i} />
					))}
				</div>
				<div className="schedules_box">
					<div className="schedules" ref={ref}>
						<Timeline time={time} />
						{rooms.map((room, i) => (
							<RoomsSchedule room={room} key={i} time={time} list={calendar_list} hovered={hovered} set_hovered={set_hovered} />
						))}
					</div>
					<div className="arrows">
						<a
							className="arrow_left"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								if (ref.current) ref.current.scrollLeft -= 65;
							}}
						></a>
						<a
							className="arrow_right"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								if (ref.current) ref.current.scrollLeft += 65;
							}}
						></a>
					</div>
				</div>
			</div>
			<Footer date={date} />
		</>
	);
};
const Header = ({ date, set_date }) => {
	const { organization } = useContext(OrganizationContext);
	const modals_context = useContext(ModalsContext);
	const [dates_cls, set_dates_cls] = useState({});
	const navigate = useNavigate();

	const update_dates = ({ year, month }) => {
		const dates = {};
		let obj = DateTime.fromFormat(date, 'yyyy-MM-dd');
		if (obj.year === year && obj.month === month) dates[obj.day] = 'active';

		obj = DateTime.fromObject({ year, month, day: 1 });

		for (let i = 1; i <= obj.daysInMonth; i++) {
			obj = obj.set({ day: i });
			const hours = organization.hours[obj.weekday];
			if (hours[0] === 0 && hours[0] === hours[1]) dates[i] = 'unavailable';
		}

		set_dates_cls(dates);
	};

	return (
		<div className="header_block ">
			<h1>Calendar</h1>
			<div className="wrapper">
				<DropdownCalendar
					selection={date}
					selected={date}
					dates_cls={dates_cls}
					clb={(date) => navigate(`/dashboard/calendar/${date}`)}
					on_month_change={update_dates}
				/>
				{/*<div className="search">
					<div className="search_fields">
						<div className="input">
							<input type="text" name="search" id="search" placeholder="Search" />
							<a href="#!" className="btn_close" alt="Button close"></a>
						</div>
					</div>
				</div>*/}
			</div>
			<button onClick={() => modals_context.show('blocked_time', { date })}>Block Time</button>
		</div>
	);
};

const CalendarView = () => {
	const { date = DateTime.now().toFormat('yyyy-MM-dd') } = useParams();
	return (
		<section className="wide calendar_section">
			<Header date={date} />
			<Main date={date} />
		</section>
	);
};

export default CalendarView;
