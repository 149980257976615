import React, { useContext, useState, useEffect } from 'react';

import ModalsContext from '#context/modals.jsx';
import BookingsContext from '#context/bookings.jsx';
import AssetsContext from '#context/assets.jsx';

import Loader from '#components/loader.jsx';

const EditRequest = () => {
	const modals_context = useContext(ModalsContext);
	const bookings_context = useContext(BookingsContext);
	const assets_context = useContext(AssetsContext);

	const [staff, set_staff] = useState();
	const [equipment, set_equipment] = useState();

	const [loading, set_loading] = useState(false);
	const [error, set_error] = useState('');

	const temp_data = {};
	if (modals_context.data.assets) modals_context.data.assets.forEach((id) => (temp_data[id] = true));

	const [selected_assets, set_selected_assets] = useState(temp_data);

	useEffect(() => {
		(async () => {
			const data = await assets_context.get_list_for_booking_editing(modals_context.data._id);
			set_staff(data.staff);
			set_equipment(data.equipment);
		})();
	}, []);

	const on_submit = async (e) => {
		e.preventDefault();
		if (loading) return;

		set_loading(true);

		let assets = [];
		for (let i in selected_assets) if (selected_assets[i]) assets.push(i);

		try {
			await bookings_context.api.update_assets(assets);
		} catch (e) {
			set_loading(false);
			set_error(e.toString());
			return;
		}

		set_loading(false);
		modals_context.hide();

		return false;
	};

	if (!staff || !equipment)
		return (
			<div className="popup">
				<Loader />
			</div>
		);

	return (
		<form className="popup blocked_time" onSubmit={on_submit}>
			<h3>Assets</h3>
			<div className="rooms_wrapper">
				<p>People</p>
				<div className="rooms">
					<div className="columns">
						{staff.map((asset, i) => (
							<div className={`checkbox ${asset.busy ? 'disabled' : ''}`} key={i}>
								<input
									type="checkbox"
									id={asset._id}
									name={asset._id}
									checked={!!selected_assets[asset._id]}
									disabled={asset.busy}
									onChange={(e) => {
										set_selected_assets({ ...selected_assets, [asset._id]: e.target.checked });
									}}
								/>
								<label htmlFor={asset._id}>{asset.name}</label>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="rooms_wrapper">
				<p>Equipment</p>
				<div className="rooms">
					<div className="columns">
						{equipment.map((asset, i) => (
							<div className={`checkbox ${asset.busy ? 'disabled' : ''}`} key={i}>
								<input
									type="checkbox"
									id={asset._id}
									name={asset._id}
									checked={!!selected_assets[asset._id]}
									disabled={asset.busy}
									onChange={(e) => {
										set_selected_assets({ ...selected_assets, [asset._id]: e.target.checked });
									}}
								/>
								<label htmlFor={asset._id}>{asset.name}</label>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="actions">
				<button
					onClick={(e) => {
						e.preventDefault();
						modals_context.hide();
					}}
				>
					Cancel
				</button>

				<button type="submit" disabled={error || loading}>
					Save
				</button>
			</div>
			{error ? (
				<span className="schedule_error">
					<span className="material-symbols-outlined">error</span>
					{error}
				</span>
			) : (
				<></>
			)}
		</form>
	);
};

export default EditRequest;
