import React, { useContext } from 'react';

import ModalsContext from '#context/modals.jsx';

import RoomDetails from '#modals/room_details.jsx';
import ForgotPassword from '#modals/forgot_password.jsx';
import SetupCleanup from '#modals/setup_cleanup.jsx';
import Authentication from '#modals/authentication.jsx';
import ContactUs from '#modals/contact_us.jsx';
import NotificationDelete from '#modals/notification_delete.jsx';
import BookingStatus from '#modals/booking_status.jsx';
import RoomAction from '#modals/room_action.jsx';
import AssetAction from '#modals/asset_action.jsx';
import SavedChanges from '#modals/saved_changes.jsx';
import BlockedTime from '#modals/blocked_time.jsx';
import CustomInputAdd from '#modals/custom_input_add.jsx';
import CustomInputDelete from '#modals/custom_input_delete.jsx';
import UserAction from '#modals/user_action.jsx';
import EditRequest from '#modals/edit_request.jsx';
import EditAssets from '#modals/edit_assets.jsx';
import EditCustom from '#modals/edit_custom.jsx';
import EditSchedule from '#modals/edit_schedule.jsx';
import CalendarExportICS from '#modals/calendar_export_ics.jsx';
import CalendarExportXLSX from '#modals/calendar_export_xlsx.jsx';

const _modal2components = new Map([
	['room_details', RoomDetails],
	['forgot_password', ForgotPassword],
	['setup_cleanup', SetupCleanup],
	['authentication', Authentication],
	['contact_us', ContactUs],
	['notification_delete', NotificationDelete],
	['booking_status', BookingStatus],
	['saved_changes', SavedChanges],
	['custom_input_add', CustomInputAdd],
	['custom_input_delete', CustomInputDelete],
	['room_action', RoomAction],
	['asset_action', AssetAction],
	['saved_changes', SavedChanges],
	['blocked_time', BlockedTime],
	['user_action', UserAction],
	['edit_request', EditRequest],
	['edit_assets', EditAssets],
	['edit_custom', EditCustom],
	['edit_schedule', EditSchedule],
	['calendar_export_ics', CalendarExportICS],
	['calendar_export_xlsx', CalendarExportXLSX],
]);

function Modals() {
	const modals_context = useContext(ModalsContext);

	if (!modals_context.modal)
		return (
			<div className="app2">
				<div className="popup_container" />
			</div>
		);

	let Modal = _modal2components.get(modals_context.modal);

	return (
		<div className="app2">
			<div
				className={`popup_container${modals_context.opened ? ' open ' : ''}${
					modals_context.modal === 'authentication' && modals_context.data.state === 'create_acccount' ? ' create_account' : ''
				}`}
			>
				<Modal {...modals_context.data} />
			</div>
		</div>
	);
}

export default Modals;
