import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import XLSX from 'xlsx';

import UsersContext from '#context/users.jsx';
import Loader from '#components/loader.jsx';
import ModalsContext from '#context/modals.jsx';

const tabs = {
	admin: 'Admins',
	staff: 'Staff',
	user: 'Users',
};

const export_emails = (users) => {
	const ws = XLSX.utils.aoa_to_sheet([
		['First Name', 'Last Name', 'Email'],
		...users.map((user) => [user.first_name || '', user.last_name || '', user.email || '']),
	]);
	const wb = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
	XLSX.writeFile(wb, `users-${new Date().toLocaleString('en-us', { year: 'numeric', month: 'numeric', day: 'numeric' })}.xlsx`);
};

const List = ({ role }) => {
	const location = useLocation();
	const loader_ref = useRef(null);
	const [load_more, set_load_more] = useState(true);

	const modals_context = useContext(ModalsContext);
	const { list, get_list, api, clear_list } = useContext(UsersContext);

	useEffect(() => {
		clear_list({ role, skip: 0 });
		set_load_more(true);
	}, [role]);

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.1,
		};
		const target = loader_ref.current;

		const observer = new IntersectionObserver(async ([entry]) => {
			if (entry.isIntersecting) {
				set_load_more(await get_list({ skip: list?.length, role }));
			}
		}, options);

		if (target) {
			observer.observe(target);
		}

		return () => {
			if (target) {
				observer.unobserve(target);
			}
		};
	}, [list]);

	if (!list) return <Loader />;

	if (!list.length) return <p className="no_items">Currently, there are no staff</p>;

	return (
		<ul className="list">
			{list.map(({ first_name, last_name, email, photo, _id }, i) => (
				<li key={i} className={`${location.hash.includes(_id) ? 'active' : ''}`}>
					<div className="user_photo">
						{photo ? <img width="40px" height="40px" src={photo} /> : <span>{`${first_name[0]} ${last_name[0]}`}</span>}
					</div>
					<div className="info">
						<p>{`${first_name} ${last_name}`}</p>
						<span>{email}</span>
					</div>
					<div className="actions">
						<a className="icon edit" href={`#profile_${_id}`} />
						<a
							className="icon delete_red"
							onClick={() =>
								modals_context.show('user_action', {
									action: 'revoke',
									text: `${first_name} ${last_name}`,
									clb: () => api.delete(_id),
								})
							}
						/>
					</div>
				</li>
			))}
			{load_more ? (
				<div style={{ height: '150px' }} ref={loader_ref}>
					<Loader />
				</div>
			) : (
				<></>
			)}
		</ul>
	);
};

const Header = ({ role }) => {
	const modals_context = useContext(ModalsContext);
	const { api, exports } = useContext(UsersContext);

	return (
		<div className="header_block">
			<h1>Accounts</h1>
			<div className="actions">
				<a
					className="icon border export"
					onClick={async () => {
						const result = await exports(role);
						export_emails(result);
					}}
				></a>
				{role === 'user' ? (
					<></>
				) : (
					<a className="button approve" onClick={() => modals_context.show('user_action', { action: role, clb: (data) => api.create(data) })}>
						+Add {tabs[role].replace('s', '')}
					</a>
				)}
			</div>

			<div className="tabs">
				{Object.keys(tabs).map((tab) => (
					<Link to={`/dashboard/users/${tab}`} className={tab === role ? 'active' : ''} key={tab}>
						{tabs[tab]}
					</Link>
				))}
			</div>
		</div>
	);
};
const UsersView = () => {
	const { role = 'admin' } = useParams();
	return (
		<section className="dashboard_list accounts_list">
			<Header role={role} />
			<List role={role} />
		</section>
	);
};

export default UsersView;
