import { DateTime } from 'luxon';

export function prettier(time) {
	if (typeof time === 'string') return time;
	let ampm = 'am';
	if (time === 0 || time === 24) return '12:00 am';
	if (time === 0.5 || time === 24.5) return '12:30 am';
	if (time === 12.5) return '12:30 pm';
	if (time === 12) return '12:00 pm';

	if (time > 12.5) {
		time -= 12;
		ampm = 'pm';
	}

	return `${Math.floor(time)}:${time % 1 === 0.5 ? '3' : '0'}0 ${ampm}`;
}

export function pretty_interval(from, to) {
	return `${prettier(from)} - ${prettier(to)}`.toUpperCase();
}

export function generate_in_range(start, end) {
	let times = [];
	let time = start;

	while (time < end) {
		times.push({ value: time, view: prettier(time) });
		time += 0.5;
	}

	return times;
}

export function format_date_YYYY_MM_DD({ year, month, day }) {
	return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

export function format_date_YYYY_MM({ year, month }) {
	return `${year}-${month < 10 ? `0${month}` : month}`;
}

export function format_date_dd_MMMM_yyyy(timestamp) {
	return DateTime.fromMillis(timestamp).toFormat('d MMMM yyyy');
}

export function full_date(timestamp) {
	return DateTime.fromMillis(timestamp).setLocale('en-GB').toLocaleString(DateTime.DATETIME_MED);
}

export function pretty_period(period) {
	if (period === 0) return `0 Hours 0 Minutes`;

	const hours = Math.floor(period);
	const minutes = (period % 1) * 60;

	return `${hours ? `${hours} Hour${hours > 1 ? 's' : ''}` : ''}${hours && minutes ? ' ' : ''}${minutes ? `${minutes} Minutes` : ''}`;
}

export function pretty_time(time) {
	const hours = Math.floor(time);
	const minutes = Math.round((time - hours) * 60);
	return DateTime.fromObject({ hours, minutes }).toFormat('HH:mm');
}

export default {
	prettier,
	generate_in_range,
	format_date_YYYY_MM_DD,
	format_date_YYYY_MM,
	pretty_interval,
	format_date_dd_MMMM_yyyy,
	full_date,
	pretty_period,
	pretty_time,
};
