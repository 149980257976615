import React, { useContext, useEffect, useRef, useState } from 'react';

import FeedbackContext from '#context/feedback';
import OrganizationContext from '#context/organization';
import ModalsContext from '#context/modals';
import Loader from '#components/loader.jsx';
import Dropdown from '#components/dropdown.jsx';
import { format_date_dd_MMMM_yyyy } from '#components/time_helpers.js';

import WYSIWYGTextArea from '#components/wysiwyg_textarea.jsx';

const rating_def = [5, 4, 3, 2, 1];

const get_rating_view = (rating) => (
	<div className="rating">
		{rating_def.map((_, i) => (
			<span key={i} className={`circle ${rating >= i + 1 ? 'full' : ''}`}></span>
		))}
	</div>
);

const generate_list = (from = 5, to = 1, chosen) => {
	const list = [{ view: 'Most recent', value: undefined, chosen: chosen === -1 }];

	for (let i = from; i >= to; i -= 1) list.push({ view: get_rating_view(i), value: i, chosen: chosen === i });

	return list;
};

const ListHeader = ({ rating, set_rating }) => (
	<div className="header_block">
		<h1>User Feedback</h1>

		<div className="dropdown_box">
			<span>Sort</span>
			<Dropdown
				list={generate_list()}
				selection={rating ? get_rating_view(rating) : 'Most recent'}
				selected={true}
				clb={(value) => set_rating(value)}
			/>
		</div>
	</div>
);

const List = ({ rating, total }) => {
	const loader_ref = useRef(null);
	const [load_more, set_load_more] = useState(true);
	const { list, get_list, stats } = useContext(FeedbackContext);

	const total_current = (rating) => {
		if (!rating) return total;
		const item = stats?.find((stat) => stat.rating === rating) || {};
		return item?.count;
	};

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.1,
		};
		const target = loader_ref.current;

		set_load_more(total_current(rating) > list?.length);

		const observer = new IntersectionObserver(async ([entry]) => {
			if (entry.isIntersecting) {
				await get_list({ skip: list?.length, rating });
			}
		}, options);

		if (target) {
			observer.observe(target);
		}

		return () => {
			if (target) {
				observer.unobserve(target);
			}
		};
	}, [list, load_more]);

	if (!list) return <Loader />;

	if (!list.length) return <p className="no_items">Currently, there are no feedback.</p>;

	return (
		<ul className="list">
			{list.map(({ name, message, rating, session_date }, i) => (
				<li key={i}>
					<div className="info">
						<h3>{name}</h3>
						{get_rating_view(rating)}
					</div>
					<p>{message}</p>
					<span>{format_date_dd_MMMM_yyyy(session_date)}</span>
				</li>
			))}
			{load_more ? (
				<div style={{ height: '150px' }} ref={loader_ref}>
					<Loader />
				</div>
			) : (
				<></>
			)}
		</ul>
	);
};
const Stats = ({ total }) => {
	const { stats } = useContext(FeedbackContext);
	const get_item = (el) => stats?.find((stat) => stat.rating === el) || {};

	return (
		<ul>
			{rating_def.map((el, i) => {
				const { count } = get_item(el);
				return (
					<li key={i}>
						<div className="rating">
							{Array(el)
								.fill('_')
								.map((_, i) => (
									<span key={i} className="circle full"></span>
								))}
						</div>
						<div className="bar">
							<div style={{ width: !count ? '0%' : (count / total) * 100 + '%' }}></div>
						</div>
						<p>{count || 0}</p>
					</li>
				);
			})}
		</ul>
	);
};

const Header = ({ total }) => (
	<div className="header_block">
		<h1>Facility Scores</h1>
		<p>{total} ratings in total</p>
	</div>
);

const FeedbackForm = () => {
	const organization_context = useContext(OrganizationContext);
	const modals_context = useContext(ModalsContext);

	const [add, set_add] = useState(organization_context.organization.feedback_add);
	const [text, set_text] = useState(organization_context.organization.feedback_add_text || '');

	const [is_sending, set_sending] = useState(false);

	const handle_submit = async (e) => {
		e.preventDefault();
		set_sending(true);
		try {
			organization_context.feedback_add({
				add,
				text,
			});
			modals_context.show('saved_changes');
		} catch (e) {
			console.error(e);
		}
		set_sending(false);
	};

	return (
		<div className="feedback_form">
			<div className="header_block">
				<h2>Feedback Form</h2>
				<p>
					Below is the feedback form that will be automatically emailed to the user after their session, provided that the feature is enabled in the
					Facility Details tab.
				</p>
			</div>

			<div class="box">
				<h3>Overall, how was your experience in our facility?</h3>
				<div className="grade">
					<p>Poor</p>
					<span>1</span>
					<span>2</span>
					<span>3</span>
					<span>4</span>
					<span>5</span>
					<p>Exceptional</p>
				</div>

				<h3>Please share any comments, questions, or concerns.</h3>
				<textarea readOnly></textarea>
			</div>

			<form onSubmit={handle_submit}>
				<div className="checkbox">
					<input type="checkbox" id="sent_email" name="sent_email" checked={add} onChange={(e) => set_add(e.target.checked)} />
					<label htmlFor="sent_email">Add Additional Note to the Feedback Form Email</label>
				</div>
				<p className="margin_bottom">You can add a custom note that will appear below the form above (5000 character max).</p>

				<WYSIWYGTextArea value={text} onChange={(e) => set_text(e.target.value)} />
				<div className="actions">
					<button
						type="reset"
						onClick={async () => {
							set_add(organization_context.organization.feedback_add);
							set_text(organization_context.organization.feedback_add_text);
						}}
						disabled={
							(text === (organization_context.organization.feedback_add_text || '') && add === organization_context.organization.feedback_add) ||
							is_sending
								? 'disabled'
								: ''
						}
					>
						Discard Changes
					</button>
					<button
						type="submit"
						disabled={
							(text === (organization_context.organization.feedback_add_text || '') && add === organization_context.organization.feedback_add) ||
							is_sending
								? 'disabled'
								: ''
						}
					>
						Save Changes
					</button>
				</div>
			</form>
		</div>
	);
};

const FeedbackView = () => {
	const { get_stats, stats, clear_list } = useContext(FeedbackContext);
	const [rating, set_rating] = useState();

	useEffect(() => {
		clear_list({ rating, skip: 0 });
		if (!stats) get_stats();
	}, [rating]);

	const total = stats?.reduce((accum, el) => (accum += el.count), 0);

	if (!stats) return <Loader />;

	return (
		<>
			<section className="dashboard_list feedback_list">
				<div className="main_list">
					<Header total={total} />
					<Stats total={total} />
					<ListHeader set_rating={set_rating} rating={rating} />
					<List rating={rating} total={total} />
				</div>
				<FeedbackForm />
			</section>
		</>
	);
};

export default FeedbackView;
