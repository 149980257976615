import React, { useState, useEffect, useContext, useRef } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import Loader from '#components/loader.jsx';
import NotificationsContenxt from '#context/notifications.jsx';
import { get_pretty_action } from '#utils/index.jsx';
import { full_date } from '#components/time_helpers.js';
import ModalsContext from '#context/modals.jsx';

function NotificationItem({ notification, next }) {
	const location = useLocation();
	const notifications_context = useContext(NotificationsContenxt);
	const modals_context = useContext(ModalsContext);

	const { booking, action, user, timestamp, _id, seen } = notification;

	let cls = '';
	if (next && (location.hash.includes(next.booking._id) || !next.seen)) cls = 'borderless';

	return (
		<li className={`${cls} ${location.hash.includes(booking._id) ? 'active' : ''} ${seen ? '' : 'unread'}`}>
			<div className="info">
				<p className="subinfo">{full_date(timestamp)}</p>
				<p>{get_pretty_action(action, user)}</p>
				<p>{`${booking?.requestInformation.session_name} on ${booking?.date}`}</p>
			</div>

			<div className="actions">
				<a className={`icon ${seen ? 'unseen' : 'seen'}`} onClick={() => notifications_context.api.seen(_id, !seen)}></a>
				<a
					className="icon delete_red"
					onClick={() => {
						modals_context.show('notification_delete', {
							clb: () => notifications_context.api.delete(_id),
						});
					}}
				></a>
				<a className="icon border arrow_right_requests" href={`#booking_${booking._id}`}></a>
			</div>
		</li>
	);
}

function NotificationList() {
	const { list, get_list, clear_list } = useContext(NotificationsContenxt);
	let { unseen } = useParams();
	const loader_ref = useRef(null);
	const [load_more, set_load_more] = useState(true);

	unseen = !!unseen;

	useEffect(() => {
		(async () => {
			set_load_more(await clear_list({ skip: 0, unseen }));
		})();
	}, [unseen]);

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.1,
		};
		const target = loader_ref.current;

		const observer = new IntersectionObserver(async ([entry]) => {
			if (entry.isIntersecting) {
				set_load_more(await get_list({ skip: list.length, unseen }));
			}
		}, options);

		if (target) {
			observer.observe(target);
		}

		return () => {
			if (target) {
				observer.unobserve(target);
			}
		};
	}, [list]);

	if (!list) return <Loader />;

	return list.length > 0 ? (
		<ul className="list">
			{list?.map((notification, i) => (
				<NotificationItem notification={notification} key={notification._id} next={list[i + 1]} />
			))}
			{load_more ? (
				<div style={{ height: '150px' }} ref={loader_ref}>
					<Loader />
				</div>
			) : (
				<></>
			)}
		</ul>
	) : (
		<p className="no_items">Currently, there are no notifications.</p>
	);
}

function Header() {
	const { unseen_notifications } = useContext(NotificationsContenxt);
	const { unseen } = useParams();

	return (
		<div className="header_block">
			<h1>Notifications</h1>
			{unseen_notifications ? <span className="counter">{unseen_notifications}</span> : <></>}

			<div className={`checkbox ${unseen ? 'checked' : ''}`}>
				<NavLink to={`/dashboard/notifications/${unseen ? '' : 'unseen'}`}>
					<input type="checkbox" id="unseen" name="unseen" className={`${unseen ? 'checked' : ''}`} />
				</NavLink>
				<label htmlFor="unseen">Unseen only</label>
			</div>
		</div>
	);
}

function NotificationsView() {
	return (
		<section className="dashboard_list notifications_list">
			<Header />
			<NotificationList />
		</section>
	);
}

export default NotificationsView;
