import React, { useContext, useState, useEffect } from 'react';

import ModalsContext from '#context/modals.jsx';
import OrganizationContext from '#context/organization.jsx';
import UserContext from '#context/user.jsx';

import DropdownCalendar from '#components/dropdown_calendar.jsx';

import bookings_api from '#api/bookings.js';
import { createEvents } from 'ics';

import { DateTime } from 'luxon';

import CONFIG from '../config.json';

function generate_dates({ year, month }, date, week_hours, minimal) {
	date = DateTime.fromFormat(date, 'yyyy-MM-dd').toObject({ year: true, month: true, day: true });

	const dates = {};

	if (year === date.year && month === date.month) dates[date.day] = 'active';

	if (minimal) {
		const now = DateTime.fromFormat(minimal, 'yyyy-MM-dd').toObject({ year: true, month: true, day: true });

		if (year === now.year && month === now.month) {
			for (let i = 1; i < now.day; i++) dates[i] = 'inactive';
		}
	}

	const datetime = DateTime.fromObject({ year, month });

	for (let i = 1; i <= datetime.daysInMonth; i++) {
		const hours = week_hours[datetime.set({ day: i }).weekday];

		if (hours[0] === 0 && hours[1] === 0) dates[i] = 'unavailable';
	}

	return dates;
}

const CalendarExportICS = () => {
	const { session } = useContext(UserContext);
	const { organization } = useContext(OrganizationContext);
	const modals_context = useContext(ModalsContext);

	const [from, set_from] = useState(modals_context.data.date || DateTime.now().toFormat('yyyy-MM-dd'));
	const [to, set_to] = useState(modals_context.data.date || DateTime.now().toFormat('yyyy-MM-dd'));

	const [dates_cls, set_dates_cls] = useState({});
	const [dates_cls2, set_dates_cls2] = useState({});

	const on_submit = async (e) => {
		e.preventDefault();

		const bookings = await bookings_api.export_ics({ session, from, to });

		const filename = `TheSimCal ${new Date().toString()}.ics`;

		const url = URL.createObjectURL(
			new File(
				[
					createEvents(
						bookings.map((booking) => ({
							startInputType: 'local',
							startOutputType: 'utc',
							endInputType: 'local',
							endOutputType: 'utc',
							start: booking.schedule.timestamp + booking.schedule.times[0].start * 60 * 60 * 1000,
							end: booking.schedule.timestamp + booking.schedule.times[2].end * 60 * 60 * 1000,
							title: booking.requestInformation.session_name || '',
							description: booking.additionalDetails || '',
							location: organization.name,
							url: `https://${CONFIG.url}`,
						}))
					).value.replace(/(DT.*:\d*T\d*)Z/gi, '$1'), //The only way to create a correct date/time
				],
				filename,
				{ type: 'text/calendar' }
			)
		);

		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = filename;

		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);

		URL.revokeObjectURL(url);

		modals_context.hide();
	};

	return (
		<form className="popup calendar_export" onSubmit={on_submit}>
			<a className="btn_close" href="#!" onClick={modals_context.hide}></a>
			<h3>Export to .ics file</h3>

			<div className="dates">
				<div className="date">
					<p>From:</p>
					<DropdownCalendar
						selected={from}
						selection={from}
						on_month_change={(current) => set_dates_cls(generate_dates(current, from, organization.hours))}
						dates_cls={dates_cls}
						clb={(current) => {
							set_from(current);
							if (DateTime.fromFormat(current, 'yyyy-MM-dd').toMillis() > DateTime.fromFormat(to, 'yyyy-MM-dd').toMillis()) {
								set_to(current);
							}
						}}
					/>
				</div>
				<div className="date">
					<p>To:</p>
					<DropdownCalendar
						selected={to}
						selection={to}
						minimum={DateTime.fromFormat(from, 'yyyy-MM-dd').toObject({ year: true, month: true })}
						on_month_change={(current) => set_dates_cls2(generate_dates(current, to, organization.hours, from))}
						dates_cls={dates_cls2}
						clb={(current) => set_to(current)}
					/>
				</div>
			</div>

			<button type="submit">Export</button>
		</form>
	);
};

export default CalendarExportICS;
