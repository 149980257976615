import React, { useContext, useState, useEffect } from 'react';

import ModalsContext from '#context/modals.jsx';
import UserContext from '#context/user.jsx';
import OrganizationContext from '#context/organization.jsx';
import BookingsContext from '#context/bookings.jsx';
import Dropdown from '#components/dropdown.jsx';
import DropdownCalendar from '#components/dropdown_calendar.jsx';
import Loader from '#components/loader.jsx';
import { prettier, pretty_period } from '#components/time_helpers.js';

import rooms_api from '#api/rooms.js';

import { DateTime } from 'luxon';

const _generate_list = (from = 0, to = 24.5, chosen) => {
	if (from < 0 || to < 0) {
		from = 0;
		to = 24.5;
	}

	const list = [];

	for (let i = from; i < to; i += 0.5) list.push({ view: prettier(i), value: i, chosen: chosen === i });

	return list;
};

const _generate_list_duration = (from = 0, to, value) => {
	const list = [];
	for (let i = from; i <= to; i += 0.5) {
		list.push({ chosen: value === i, view: pretty_period(i), value: i });
	}

	return list;
};

function generate_dates({ year, month }, date, week_hours) {
	date = DateTime.fromFormat(date, 'yyyy-MM-dd').toObject({ year: true, month: true, day: true });

	const dates = {};

	if (year === date.year && month === date.month) dates[date.day] = 'active';

	const now = DateTime.now().toObject({ year: true, month: true, day: true });

	if (year === now.year && month === now.month) {
		for (let i = 1; i < now.day; i++) dates[i] = 'inactive';
	}

	const datetime = DateTime.fromObject({ year, month });

	for (let i = 1; i < datetime.daysInMonth; i++) {
		const hours = week_hours[datetime.set({ day: i }).weekday];

		if (hours[0] === 0 && hours[1] === 0) dates[i] = 'unavailable';
	}

	return dates;
}

const EditSchedule = () => {
	const { organization } = useContext(OrganizationContext);
	const modals_context = useContext(ModalsContext);
	const bookings_context = useContext(BookingsContext);
	const { session } = useContext(UserContext);
	const [rooms, set_rooms] = useState(undefined);

	const [date, set_date] = useState(modals_context.data.date || DateTime.now().toFormat('yyyy-MM-dd'));

	const hours = organization.hours[DateTime.fromFormat(date, 'yyyy-MM-dd').weekday];

	const [dates_cls, set_dates_cls] = useState({});

	const [selected_rooms, set_selected_rooms] = useState(modals_context.data.rooms);

	const [start, set_start] = useState(modals_context.data.start);
	const [setup, set_setup] = useState(modals_context.data.setup);
	const [duration, set_duration] = useState(modals_context.data.duration);
	const [cleanup, set_cleanup] = useState(modals_context.data.cleanup);

	const [loading, set_loading] = useState(false);
	const [error, set_error] = useState('');

	useEffect(() => {
		(async () => {
			const rooms = await rooms_api.list_all({ session });
			set_rooms(rooms);

			for (let i in selected_rooms) {
				rooms.forEach((room) => {
					if (room.uuid === i) {
						selected_rooms[room._id] = selected_rooms[i];
						delete selected_rooms[i];
					}
				});
			}

			set_selected_rooms({ ...selected_rooms });
		})();
	}, [1]);

	const on_submit = async (e) => {
		e.preventDefault();
		if (loading) return;

		set_loading(true);
		set_error('');

		let rooms_array = [];
		for (let i in selected_rooms) if (selected_rooms[i]) rooms_array.push(i);

		if (rooms_array.length === 0) {
			set_error('Room selection required');
			set_loading(false);
			return false;
		}

		try {
			await bookings_context.api.update_schedule({ date, start, setup, duration, cleanup, rooms: rooms_array });
		} catch (e) {
			set_loading(false);
			switch (e) {
				case 'invalid_timeslot_rooms':
					set_error('Requested parameters conflict with another booking: rooms');
					break;
				case 'invalid_timeslot_assets':
					set_error('Requested parameters conflict with another booking: assets');
					break;
				default:
					set_error('Unknown server error');
					break;
			}

			return;
		}

		set_loading(false);
		modals_context.hide();
	};

	if (!rooms) return <Loader />;

	const date_set = (current) => {
		set_date(current);

		const hours = organization.hours[DateTime.fromFormat(current, 'yyyy-MM-dd').weekday];

		set_start(Math.max(hours[0], start), Math.min(hours[1], start));
		set_error('');
	};

	return (
		<form className="popup blocked_time" onSubmit={on_submit}>
			<h3>Booking Information</h3>

			<div className="dates intervals">
				<div className="date">
					<p>Date</p>
					<DropdownCalendar
						selection={date}
						selected={date}
						minimum={DateTime.now().toObject({ year: true, month: true })}
						on_month_change={(current) => set_dates_cls(generate_dates(current, date, organization.hours))}
						dates_cls={dates_cls}
						clb={date_set}
					/>
				</div>
				{modals_context.data.blocked ? (
					<>
						<div className="range">
							<p>Block Start Time</p>
							<Dropdown
								selection={prettier(start)}
								selected={true}
								list={_generate_list(hours[0], hours[1] + 0.5 - duration, start)}
								clb={(value) => {
									set_start(value);
									set_error('');
								}}
							/>
						</div>
						<div className="range">
							<p>Block Duration</p>
							<Dropdown
								selection={pretty_period(duration)}
								selected={true}
								list={_generate_list_duration(0, hours[1] - start, duration)}
								clb={(value) => {
									set_duration(value);
									set_error('');
								}}
							/>
						</div>
					</>
				) : (
					<>
						<div className="range">
							<p>Setup Start Time</p>
							<Dropdown
								selection={prettier(start)}
								selected={true}
								list={_generate_list(hours[0], hours[1] + 0.5 - setup - duration - cleanup, start)}
								clb={(value) => {
									set_start(value);
									set_error('');
								}}
							/>
						</div>
						<div className="range">
							<p>Setup Duration</p>
							<Dropdown
								selection={pretty_period(setup)}
								selected={true}
								list={_generate_list_duration(0, hours[1] - start - duration - cleanup, setup)}
								clb={(value) => {
									set_setup(value);
									set_error('');
								}}
							/>
						</div>
						<div className="range">
							<p>Session Duration</p>
							<Dropdown
								selection={pretty_period(duration)}
								selected={true}
								list={_generate_list_duration(0.5, hours[1] - start - setup - cleanup, duration)}
								clb={(value) => {
									set_duration(value);
									set_error('');
								}}
							/>
						</div>

						<div className="range">
							<p>Cleanup Duration</p>
							<Dropdown
								selection={pretty_period(cleanup)}
								selected={true}
								list={_generate_list_duration(0, hours[1] - start - setup - duration, cleanup)}
								clb={(value) => {
									set_cleanup(value);
									set_error('');
								}}
							/>
						</div>
					</>
				)}
			</div>

			<div className="rooms_wrapper">
				<p>Rooms</p>
				<div className="rooms">
					<div className="columns">
						{rooms.map((room, i) => (
							<div className="checkbox" key={i}>
								<input
									type="checkbox"
									id={room._id}
									name={room._id}
									checked={!!selected_rooms[room._id]}
									onChange={(e) => {
										set_selected_rooms({ ...selected_rooms, [room._id]: e.target.checked });
										set_error('');
									}}
								/>
								<label htmlFor={room._id}>
									{room.roomType}&nbsp;{room.name}
								</label>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="actions">
				<button
					onClick={(e) => {
						e.preventDefault();
						modals_context.hide();
					}}
				>
					Cancel
				</button>

				<button type="submit" disabled={error || loading}>
					Save
				</button>
			</div>
			{error ? (
				<span className="schedule_error">
					<span class="material-symbols-outlined">error</span>
					{error}
				</span>
			) : (
				<></>
			)}
		</form>
	);
};

export default EditSchedule;
