import React from 'react';
import Headerbar from '#components/headerbar.jsx';
import { useNavigate } from 'react-router-dom';
import CreateAccountForm from '#components/create_account_form.jsx';

const CreateAccount = () => {
	const navigate = useNavigate();

	return (
		<div className="app2">
			<Headerbar page="/create_account" />
			<CreateAccountForm submit_callback={() => navigate('/')} sign_in_callback={() => navigate('/sign_in')} />
		</div>
	);
};

export default CreateAccount;
