import React, { useContext } from 'react';

import ModalsContext from '#context/modals.jsx';

const _texts = new Map([
	[
		'leave',
		{
			header: 'Leave the Page?',
			text: 'Changes made will not be saved.',
		},
	],
	[
		'delete',
		{
			header: 'Delete Auditorium Room Type?',
			text: "Are you sure you want to delete this room type? You can't undo this action.",
		},
	],
]);

const RoomAction = ({ action, clb }) => {
	const modals_context = useContext(ModalsContext);

	const { header, text } = _texts.get(action) || {};

	const on_click = (e) => {
		e.stopPropagation();
		e.preventDefault();

		try {
			clb();
			modals_context.hide();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className="popup">
			<div className="account_form booking">
				<h3>{header}</h3>
				<p>{text}</p>

				<div className="actions">
					<button
						onClick={(e) => {
							e.preventDefault();
							modals_context.hide();
						}}
					>
						No
					</button>

					<button type="submit" onClick={on_click}>
						Yes
					</button>
				</div>
			</div>
		</div>
	);
};

export default RoomAction;
