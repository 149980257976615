import fetch from './fetch.js';
import post from './post.js';
import get from './get.js';

export default {
	list_all: (session) => get('assets/list_all', { session }),
	list_for_booking: (booking) => get('assets/list_for_booking', { booking }),
	list_for_booking_editing: ({ session, booking }) => get('assets/list_for_booking_editing', { session, booking }),
	get: ({ session, asset }) => get('assets/get', { session, asset }),
	create: ({ session, name, type, ids }) => {
		const data = { session, name, type };
		if (type === 'equipment') data.ids = ids;
		return post('assets/create', data);
	},
	update: ({ session, asset, name, ids }) => {
		const data = { session, asset, name };
		if (ids) data.ids = ids;
		return post('assets/update', data);
	},
	delete: ({ session, asset }) => post('assets/delete', { session, asset }),
	update_indexes: ({ session, indexes }) => post('assets/update/indexes', { session, indexes }), //indexes - array of {asset:<_id>,index:<number>}
};
