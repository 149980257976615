import React, { useContext, useState } from 'react';

import ModalsContext from '#context/modals.jsx';
import OrganizationContext from '#context/organization.jsx';

import Dropdown from '#components/dropdown.jsx';
import { pretty_period } from '#components/time_helpers.js';

const get_list = (count, value) => {
	const list = [];
	list.push({ chosen: value === undefined, view: 'Default', value: undefined });

	for (let i = 0; i < count; i += 0.5) {
		list.push({ chosen: value === i, view: pretty_period(i), value: i });
	}

	return list;
};

const SetupCleanup = () => {
	const modals_context = useContext(ModalsContext);
	const organization_context = useContext(OrganizationContext);
	const max_duration = organization_context.organization.max_duration;

	const [setup, set_setup] = useState(modals_context.data.setup);
	const [cleanup, set_cleanup] = useState(modals_context.data.cleanup);

	const on_click = () => {
		modals_context.data.setup = setup;
		modals_context.data.cleanup = cleanup;
		modals_context.hide();
	};

	return (
		<div className="popup edit_setup">
			<a className="btn_close" href="#!" onClick={modals_context.hide}></a>
			<h3>Edit Setup & Cleanup Times</h3>
			<h4>Note: </h4>
			<div className="text">Do not edit these settings unless directed by staff.</div>
			<div className="row">
				<div className="time">Setup time: </div>
				<Dropdown
					cls="time_small"
					selection={pretty_period(setup) || 'Default'}
					selected={setup !== undefined}
					list={get_list(max_duration, setup)}
					clb={(value) => {
						set_setup(value);
					}}
				/>
			</div>
			<div className="row">
				<div className="time">Cleanup time: </div>
				<Dropdown
					cls="time_small"
					selection={pretty_period(cleanup) || 'Default'}
					selected={cleanup !== undefined}
					list={get_list(max_duration, cleanup)}
					clb={(value) => {
						set_cleanup(value);
					}}
				/>
			</div>
			<a className="btn_save" href="#!" onClick={on_click}>
				Save
			</a>
		</div>
	);
};

export default SetupCleanup;
