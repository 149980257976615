import React, { useState, useContext } from 'react';
import UserContext from '#context/user.jsx';
import api_user from '#api/user.js';

const SignIn = ({ submit_callback, forgot_password_callback, create_account_callback }) => {
	const user_context = useContext(UserContext);

	const [email, set_email] = useState(user_context.email);
	const [password, set_password] = useState('');
	const [password_visible, set_password_visible] = useState(false);
	const [error, set_error] = useState('');
	const [loading, set_loading] = useState(false);

	const on_submit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		set_loading(true);

		(async () => {
			try {
				const { user, session } = await api_user.sign_in({ email, password });
				user_context.user = user;
				user_context.session = session;
				submit_callback(true);
			} catch (e) {
				set_loading(false);
				set_error('Invalid email or password');
			}
		})();

		return false;
	};

	const email_change = (e) => {
		set_email(e.target.value);
		set_error('');
	};

	const password_change = (e) => {
		set_password(e.target.value);
		set_error('');
	};

	return (
		<form action="#" className="account_form" onSubmit={on_submit}>
			<h3>Sign In</h3>

			<div className={`input${error ? ' with_error' : ''}`}>
				<label htmlFor="email">Email</label>
				<input type="email" name="email" id="email" value={email} onChange={email_change} placeholder="&nbsp;" required />
				<span className="error"></span>
			</div>

			<div className={`input${error ? ' with_error' : ''}`}>
				<label htmlFor="password">Password</label>
				<input
					type={password_visible ? 'text' : 'password'}
					name="password"
					id="password"
					value={password}
					onChange={password_change}
					placeholder="&nbsp;"
					required
				/>
				<span className="error">{error}</span>
				<span className={`toggler${password_visible ? ' toggled' : ''}`} onClick={() => set_password_visible(!password_visible)} />
			</div>

			<div className="subform">
				<div className="input checkbox">
					<input
						type="checkbox"
						name="remember_me"
						id="remember_me"
						onChange={(e) => (user_context.remember_me = e.target.checked)}
						checked={user_context.remember_me}
					/>
					<label htmlFor="remember_me">Remember Me</label>
				</div>
				<a
					href="#!"
					onClick={() => {
						forgot_password_callback(email);
					}}
				>
					Forgot Password?
				</a>
			</div>

			<button type="submit" disabled={!loading && email && password ? '' : 'disabled'}>
				Sign In
			</button>

			<p className="account_options">
				No account?{' '}
				<a href="#!" onClick={create_account_callback}>
					Create one
				</a>
			</p>
		</form>
	);
};

export default SignIn;
