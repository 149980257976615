import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

import Headerbar from '#components/headerbar.jsx';
import Stepper from '#components/booking_stepper.jsx';

import NewBookingContext from '#context/new_booking.jsx';
import UserContext from '#context/user.jsx';

const BookingDetails = () => {
	const navigate = useNavigate();
	const new_booking_context = useContext(NewBookingContext);
	const user_context = useContext(UserContext);

	const custom_fields = new_booking_context.custom_fields;
	const [errors, set_errors] = useState({});
	const [loading, set_loading] = useState(false);
	const is_disabled = new_booking_context.warning;

	const state = {
		data: new_booking_context.form_data,
		custom_data: new_booking_context.custom_data,
		files: new_booking_context.files,
		set_form_data: (field, value, is_required) => {
			new_booking_context.form_data = { ...new_booking_context.form_data, [field]: value };

			state._validate(field, value, is_required);
		},
		set_custom_data: (field, value, is_required) => {
			new_booking_context.custom_data = { ...new_booking_context.custom_data, [field]: value };

			state._validate(field, value, is_required);
		},
		set_files: (files) => {
			new_booking_context.files = files;
		},
		add_files: (files) => {
			new_booking_context.files = [...new_booking_context.files, ...files];
		},
		errors,
		add_error: (field, value) => {
			if (errors[field] === value) return;
			set_errors({ ...errors, [field]: value });
		},
		clear_errors: () => {
			let data = {};
			set_errors(data);
		},
		_validate: (field, value, is_required) => {
			let error;
			switch (value.constructor) {
				case Array:
					if (is_required) {
						error = 'This field is required';
						value.forEach((value) => {
							if (value) error = '';
						});
					} else {
						error = '';
					}
					break;
				default:
					error = value || !is_required ? '' : 'This field is required';
					break;
			}

			state.add_error(field, error);
		},
	};

	useEffect(() => {
		if (user_context.user) {
			const { first_name, last_name, phone, email } = user_context.user;
			new_booking_context.form_data = { ...new_booking_context.form_data, ...{ first_name, last_name, phone, email } };
		}
	}, [user_context]);

	let form_valid = !loading;

	const required_form_fields = new Set(['first_name', 'last_name', 'email', 'session', 'attendees']);

	custom_fields.forEach(({ _id, required }) => {
		if (required && !required_form_fields.has(_id)) {
			required_form_fields.add(_id);
		}
	});

	required_form_fields.forEach((field) => {
		if (!state.data[field] && !state.custom_data[field]) form_valid = false;
		if (state.errors[field]) form_valid = false;
	});

	const on_change = ({ target: { name, value, required } }) => {
		state.set_form_data(name, value, required);
	};

	const on_submit = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (!form_valid) return false;

		state.clear_errors();
		set_loading(true);

		try {
			await new_booking_context.submit();
			// request on server
			set_loading(false);
			navigate('/new_booking/finished');

			state.set_custom_data({});
		} catch (error) {
			console.log(error);
			set_loading(false);
		}

		return false;
	};

	const on_file_change = (event) => {
		const files_to_add = [];
		const invalid_files = [];

		state.add_error('files', '');

		Array.from(event.target.files).forEach((file) => {
			if (file.size <= 100 * 1024 * 1024) {
				files_to_add.push(file);
			} else {
				invalid_files.push(file.name);
			}
		});

		if (invalid_files.length > 0) {
			const message = `Attachments ${invalid_files.join(', ')} must be no larger than 100mb`;
			state.add_error('files', message);
			return;
		}

		state.add_files(files_to_add);
	};

	const delete_file = (index) => {
		const updated_files = [...state.files];
		updated_files.splice(index, 1);
		state.set_files(updated_files);
	};

	return (
		<div className="app2">
			<Headerbar page="/new_booking/details" back_clb={() => navigate('/new_booking/datetimes')} disabled={is_disabled} />
			<Stepper
				back_clb={() => navigate('/new_booking/datetimes')}
				step_1_clb={() => navigate('/new_booking/rooms')}
				step_2_clb={() => navigate('/new_booking/datetimes')}
				current_step={3}
				disabled={is_disabled}
			/>

			<form action="#" className="account_form details" onSubmit={on_submit}>
				<h3>Session Information</h3>
				<div className="input">
					<label htmlFor="first_name">
						First Name<span>*</span>
					</label>
					<input
						type="text"
						name="first_name"
						id="first_name"
						placeholder="&nbsp;"
						required
						value={state.data.first_name}
						onChange={on_change}
						disabled={is_disabled}
					/>
					<span className="error">{state.errors.first_name}</span>
				</div>
				<div className="input">
					<label htmlFor="last_name">
						Last Name<span>*</span>
					</label>
					<input
						type="text"
						name="last_name"
						id="last_name"
						placeholder="&nbsp;"
						required
						value={state.data.last_name}
						onChange={on_change}
						disabled={is_disabled}
					/>
					<span className="error">{state.errors.last_name}</span>
				</div>
				<div className="input">
					<label htmlFor="email">
						Email<span>*</span>
					</label>
					<input
						type="email"
						name="email"
						id="email"
						placeholder="&nbsp;"
						required
						value={state.data.email}
						onChange={on_change}
						disabled={is_disabled}
					/>
					<span className="error">{state.errors.email}</span>
				</div>
				<div className="input">
					<label htmlFor="notification_emails">CC Email Address(es) for Notifications</label>
					<ReactMultiEmail
						name="notification_emails"
						emails={state.data.notification_emails}
						onChange={(value) => on_change({ target: { name: 'notification_emails', value, required: false } })}
						autoFocus={false}
						getLabel={(email, index, remove) => {
							return (
								<div data-tag key={index}>
									<div data-tag-item>{email}</div>
									<span data-tag-handle onClick={() => remove(index)}>
										×
									</span>
								</div>
							);
						}}
					/>
					<span className="error">{state.errors.notification_emails}</span>
				</div>
				<div className="input">
					<label htmlFor="phone">Phone Number</label>
					<input type="tel" name="phone" id="phone" placeholder="&nbsp;" value={state.data.phone} onChange={on_change} disabled={is_disabled} />
					<span className="error">{state.errors.phone}</span>
				</div>
				<div className="input">
					<label htmlFor="session">
						Session Name<span>*</span>
					</label>
					<input
						type="text"
						name="session"
						id="session"
						placeholder="&nbsp;"
						required
						value={state.data.session}
						onChange={on_change}
						disabled={is_disabled}
					/>
					<span className="error">{state.errors.session}</span>
				</div>
				<div className="input">
					<label htmlFor="attendees">
						Number of Attendees<span>*</span>
					</label>
					<input
						type="number"
						name="attendees"
						id="attendees"
						placeholder="&nbsp;"
						required
						value={state.data.attendees}
						onChange={on_change}
						disabled={is_disabled}
					/>
					<span className="error">{state.errors.attendees}</span>
				</div>
				<div className="input information">
					<div className="title">Details</div>
					<p className="text">
						In order to reduce the amount of correspondence required please let us know as much information as possible about your requested session.
					</p>
					<textarea
						name="details"
						id="details"
						placeholder="&nbsp;"
						cols="30"
						rows="10"
						value={state.data.details}
						onChange={on_change}
						disabled={is_disabled}
					></textarea>
				</div>
				<div className="input files">
					<div className="title">File Upload</div>
					<p className="text">Please upload any files (PowerPoint, Media, etc.) that will assist in the session</p>
					<div className="select_file">
						<input type="file" name="file" id="file" multiple onChange={on_file_change} disabled={is_disabled} />
						<label htmlFor="file">Select File</label>
						<div className="limit">Max 100mb</div>
					</div>
					<span className="error">{state.errors.files}</span>
					<ul className="selected_list">
						{state.files.map((file, i) => (
							<li key={i}>
								{file.name}
								<a href="#!" className="btn_close" onClick={() => delete_file(i)}></a>
							</li>
						))}
					</ul>
				</div>
				{custom_fields.length > 0 && (
					<div className="additional_info">
						<h3>Additional Information</h3>

						{custom_fields.map(({ _id, title, type, required, placeholder, options, display }) => {
							switch (type) {
								case 'Radio':
									const radio_options = options.split('\n');
									return (
										<div className="input" key={_id}>
											<label>
												{display ? title : ''}
												{required ? <span>*</span> : ''}
											</label>
											{radio_options.map((option) => (
												<div className="form_radio" key={`${_id}_${option}`}>
													<input
														type="radio"
														name={_id}
														value={option}
														checked={state.custom_data[_id] === option}
														id={`${_id}_${option}`}
														required={required}
														onChange={(e) => state.set_custom_data(_id, e.target.value, required)}
														disabled={is_disabled}
													/>
													<label htmlFor={`${_id}_${option}`}>{option}</label>
												</div>
											))}
											<span className="error">{state.errors[_id]}</span>
										</div>
									);
								case 'Checkboxes':
									const checkbox_options = options.split('\n');
									return (
										<div className="input" key={_id}>
											<label>
												{display ? title : ''}
												{required ? <span>*</span> : ''}
											</label>
											{checkbox_options.map((option, i) => (
												<div className="input checkbox" key={`${_id}_${option}`}>
													<input
														type="checkbox"
														name={_id}
														value={option}
														checked={state.custom_data[_id] && state.custom_data[_id][i]}
														id={`${_id}_${option}`}
														onChange={(e) => {
															let data = state.custom_data[_id] || [];
															data[i] = e.target.checked;
															state.set_custom_data(_id, data, required);
														}}
														disabled={is_disabled}
													/>
													<label htmlFor={`${_id}_${option}`}>{option}</label>
												</div>
											))}
											<span className="error">{state.errors[_id]}</span>
										</div>
									);

								case 'Text Field Input':
									return (
										<div className="input" key={_id}>
											<label htmlFor={_id}>
												{display ? title : ''}
												{required ? <span>*</span> : ''}
											</label>
											<input
												type="text"
												id={_id}
												placeholder={placeholder}
												required={required}
												value={state.custom_data[_id]}
												onChange={(e) => state.set_custom_data(_id, e.target.value, required)}
												disabled={is_disabled}
											/>
											<span className="error">{state.errors[_id]}</span>
										</div>
									);

								case 'Long Text Field Input':
									return (
										<div className="input" key={_id}>
											<label htmlFor={_id}>
												{display ? title : ''}
												{required ? <span>*</span> : ''}
											</label>
											<textarea
												id={_id}
												placeholder={placeholder}
												required={required}
												value={state.custom_data[_id]}
												onChange={(e) => state.set_custom_data(_id, e.target.value, required)}
												disabled={is_disabled}
											></textarea>
											<span className="error">{state.errors[_id]}</span>
										</div>
									);

								case 'Number Input':
									return (
										<div className="input" key={_id}>
											<label htmlFor={_id}>
												{display ? title : ''}
												{required ? <span>*</span> : ''}
											</label>
											<input
												type="number"
												id={_id}
												placeholder={placeholder}
												required={required}
												value={state.custom_data[_id]}
												onChange={(e) => state.set_custom_data(_id, +e.target.value, required)}
												disabled={is_disabled}
											/>
											<span className="error">{state.errors[_id]}</span>
										</div>
									);

								case 'Checkbox':
									return (
										<div className="input checkbox" key={_id}>
											<input
												type="checkbox"
												id={_id}
												required={required}
												checked={state.custom_data[_id]}
												onChange={(e) => state.set_custom_data(_id, e.target.checked, required)}
												disabled={is_disabled}
											/>
											<label htmlFor={_id}>
												{display ? title : ''}
												{required ? <span>*</span> : ''}
											</label>
										</div>
									);
								case 'Note':
									return (
										<div className="note" key={_id}>
											<div className="text" dangerouslySetInnerHTML={{ __html: title }}></div>
										</div>
									);
								case 'Divider':
									return <hr key={_id} />;

								default:
									return <></>;
							}
						})}
					</div>
				)}
				<button type="submit" disabled={!loading && form_valid && !is_disabled ? '' : 'disabled'}>
					Submit Request
				</button>
				<p className="message">{new_booking_context.warning || ''}</p>
			</form>
		</div>
	);
};

export default BookingDetails;
