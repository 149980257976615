import React, { useState, useContext } from 'react';
import UserContext from '#context/user.jsx';

import user_api from '#api/user.js';

const _form_fields = ['first_name', 'last_name', 'phone', 'email', 'password', 'confirm_password'];

const CreateAccount = ({ submit_callback, sign_in_callback }) => {
	const user_context = useContext(UserContext);

	let temp = {};
	let temp2 = {};
	_form_fields.forEach((field) => (temp[field] = ''));
	_form_fields.forEach((field) => (temp2[field] = ''));

	const [data, set_data] = useState(temp);
	const [errors, set_errors] = useState(temp2);

	const state = {
		data,
		set: (field, value) => {
			let new_data = { ...data, [field]: value };
			set_data(new_data);

			const error = value ? '' : 'This field is required';
			state.set_error(field, error);

			state.set_error('confirm_password', new_data.password !== new_data.confirm_password ? 'Please enter the same password' : '');
		},
		errors,
		set_error: (field, value) => {
			if (errors[field] === value) return;
			set_errors({ ...errors, [field]: value });
		},
		clear_errors: () => {
			let data = {};
			_form_fields.forEach((field) => (data[field] = ''));
			set_errors(data);
		},
	};

	const [password_visible, set_password_visible] = useState(false);

	const [loading, set_loading] = useState(false);

	let form_valid = !loading;

	_form_fields.forEach((field) => {
		if (!state.data[field]) form_valid = false;
		if (state.errors[field]) form_valid = false;
	});

	const on_submit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (!form_valid) return false;

		if (state.data.password !== state.data.confirm_password) {
			state.set_error('confirm_password', 'Please enter the same password');
			return false;
		}

		state.clear_errors();

		set_loading(true);

		(async () => {
			//TODO storage and newer version
			try {
				let form_data = {};

				_form_fields.filter((field) => field !== 'confirm_password').forEach((field) => (form_data[field] = state.data[field]));

				const { user, session } = await user_api.create_account(form_data);

				user_context.user = user;
				user_context.session = session;

				submit_callback(true);

				set_loading(false);
			} catch (e) {
				set_loading(false);
				switch (e) {
					case 'exist':
					default:
						state.set_error('email', 'A user with this email already exists');
						break;
				}
			}
		})();

		return false;
	};

	return (
		<form action="#" className="account_form" onSubmit={on_submit}>
			<h3>Create Account</h3>

			<div className={`input${state.errors.first_name ? ' with_error' : ''}`}>
				<label htmlFor="first_name">First Name*</label>
				<input
					type="text"
					name="first_name"
					id="first_name"
					placeholder="&nbsp;"
					required
					value={state.data.first_name}
					onChange={(e) => state.set('first_name', e.target.value)}
				/>
				<span className="error">{state.errors.first_name}</span>
			</div>

			<div className={`input${state.errors.last_name ? ' with_error' : ''}`}>
				<label htmlFor="last_name">Last Name*</label>
				<input
					type="text"
					name="last_name"
					id="last_name"
					placeholder="&nbsp;"
					required
					value={state.data.last_name}
					onChange={(e) => state.set('last_name', e.target.value)}
				/>
				<span className="error">{state.errors.last_name}</span>
			</div>

			<div className={`input${state.errors.email ? ' with_error' : ''}`}>
				<label htmlFor="email">Email*</label>
				<input
					type="email"
					name="email"
					id="email"
					placeholder="&nbsp;"
					required
					value={state.data.email}
					onChange={(e) => state.set('email', e.target.value)}
				/>
				<span className="error">{state.errors.email}</span>
			</div>

			<div className={`input${state.errors.phone ? ' with_error' : ''}`}>
				<label htmlFor="phone">Phone Number</label>
				<input
					type="tel"
					name="phone"
					id="phone"
					placeholder="&nbsp;"
					value={state.data.phone}
					onChange={(e) => state.set('phone', e.target.value)}
				/>
				<span className="error">{state.errors.phone}</span>
			</div>

			<div className={`input${state.errors.password ? ' with_error' : ''}`}>
				<label htmlFor="password">Password*</label>
				<input
					type={password_visible ? 'text' : 'password'}
					name="password"
					id="password"
					placeholder="&nbsp;"
					required
					value={state.data.password}
					onChange={(e) => state.set('password', e.target.value)}
				/>
				<span className="error">{state.errors.password}</span>
				<span className={`toggler${password_visible ? ' toggled' : ''}`} onClick={() => set_password_visible(!password_visible)} />
			</div>

			<div className={`input${state.errors.confirm_password ? ' with_error' : ''}`}>
				<label htmlFor="confirm_password">Confirm Password*</label>
				<input
					type={password_visible ? 'text' : 'password'}
					name="confirm_password"
					id="confirm_password"
					placeholder="&nbsp;"
					required
					value={state.data.confirm_password}
					onChange={(e) => state.set('confirm_password', e.target.value)}
				/>
				<span className="error">{state.errors.confirm_password}</span>
				<span className={`toggler${password_visible ? ' toggled' : ''}`} onClick={() => set_password_visible(!password_visible)} />
			</div>

			<button type="submit" disabled={!loading && form_valid ? '' : 'disabled'}>
				Create Account
			</button>

			<p className="account_options">
				Have an account?{' '}
				<a href="#!" onClick={sign_in_callback}>
					Sign in
				</a>
			</p>
		</form>
	);
};

export default CreateAccount;
