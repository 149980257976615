import React, { useState, useContext } from 'react';

import ModalsContext from '#context/modals.jsx';

import WYSIWYGTextArea from '#components/wysiwyg_textarea.jsx';

const _texts = new Map([
	[
		'decline',
		{
			header: 'Decline Booking?',
			text: 'Are you sure you want to decline this booking? You can’t undo this action.',
		},
	],
	[
		'cancel',
		{
			header: 'Cancel Booking?',
			text: 'Are you sure you want to cancel this booking? You can’t undo this action.',
		},
	],
	[
		'pending',
		{
			header: 'Remain Pending?',
			text: 'Leave booking as pending, often used when the user has not supplied all the required information.',
		},
	],
	[
		'approve',
		{
			header: 'Approve Booking?',
			text: 'Are you sure you want to approve this booking?',
		},
	],
]);

const BookingAction = ({ status, clb }) => {
	const modals_context = useContext(ModalsContext);

	const [note, set_note] = useState('');

	const on_submit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		try {
			clb({ status, note });
			modals_context.hide();
		} catch (e) {
			console.log(e);
		}
	};

	const { header, text } = _texts.get(status) || {};

	return (
		<div className="popup">
			<form className="account_form booking" onSubmit={on_submit}>
				<h3>{header}</h3>
				<p>{text}</p>
				<div className="input message">
					<WYSIWYGTextArea placeholder="Add comment" value={note} onChange={(e) => set_note(e.target.value)} />
				</div>
				<div className="actions">
					<button
						onClick={(e) => {
							e.preventDefault();
							modals_context.hide();
						}}
					>
						No
					</button>

					<button type="submit">Yes</button>
				</div>
			</form>
		</div>
	);
};

export default BookingAction;
