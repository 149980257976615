import React, { useState, useContext } from 'react';

import api_feedback from '#api/feedbacks.js';

import UserContext from '#context/user.jsx';

const FeedbackContext = React.createContext();

function FeedbackProvider(props) {
	const { session } = useContext(UserContext);
	const [list, set_list] = useState(undefined);
	const [stats, set_stats] = useState();

	const get_list = async (data, duplicate = false) => {
		const response = await api_feedback.list({ session, ...data });

		let new_list = new Map((store.list || []).map((el) => [el._id, el]));

		if (!duplicate)
			response.forEach((el) => {
				if (new_list.has(el._id)) {
					if (!duplicate) {
						get_list({ ...data, skip: 0, limit: list.length }, true);
						duplicate = true;
					}
				}
				new_list.set(el._id, el);
			});
		new_list = Array.from(new_list.values());

		set_list(new_list);
	};

	const clear_list = async (data) => {
		set_list(undefined);
		set_list(await api_feedback.list({ session, ...data }));
	};

	const get_stats = async () => {
		set_stats(undefined);
		set_stats(await api_feedback.stats({ session }));
	};

	const store = {
		get list() {
			return list;
		},
		set list(_) {},
		get stats() {
			return stats;
		},
		set stats(_) {},
		get_list,
		get_stats,
		clear_list,
	};

	return <FeedbackContext.Provider value={store}>{props.children}</FeedbackContext.Provider>;
}

export default FeedbackContext;
export { FeedbackProvider };
