import post from './post.js';
import get from './get.js';

export default {
	list: () => get('custom_inputs'),
	create: ({ session, required, type, title, options, placeholder, display }) =>
		post('custom_inputs/create', { session, required, type, title, options, placeholder, display }), //Returns created object
	update: ({ session, custom_input, required, type, title, options, placeholder, display }) =>
		post('custom_inputs/update', { session, custom_input, required, type, title, options, placeholder, display }),
	update_indexes: ({ session, indexes }) => post('custom_inputs/update/indexes', { session, indexes }), //indexes - array of {custom_input:<_id>,index:<number>}
	delete: ({ session, custom_input }) => post('custom_inputs/delete', { session, custom_input }),
};
