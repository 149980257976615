import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OrganizationContext from '#context/organization';

import Headerbar from '#components/headerbar.jsx';
import api_feedback from '#api/feedbacks.js';

const FeedbackForm = () => {
	const organization_context = useContext(OrganizationContext);

	const { rating, token } = useParams();
	const navigate = useNavigate();

	const [message, set_message] = useState();
	const [valid, set_valid] = useState();
	const [submitted, set_submitted] = useState();

	useEffect(() => {
		if (!token) return;
		(async () => {
			const result = await api_feedback.get({ token });
			set_valid(result.valid);
		})();
	}, [token]);

	if (!valid) {
		return (
			<div className="app2">
				<Headerbar page="/feedback" />

				<div className="dashboard feedback_client">
					<div className="feedback_form">
						<div className="header_block">
							<h2>Feedback Form</h2>
						</div>

						<h3>Your feedback for this session has already been received.</h3>
					</div>
				</div>
			</div>
		);
	}

	if (submitted) {
		return (
			<div className="app2">
				<Headerbar page="/feedback" />

				<div className="dashboard feedback_client">
					<div className="feedback_form">
						<div className="header_block">
							<h2>Feedback Form</h2>
						</div>

						<h3>Your feedback was received, thank you!</h3>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="app2">
			<Headerbar page="/feedback" />

			<div className="dashboard feedback_client">
				<form
					className="feedback_form"
					onSubmit={async (e) => {
						e.preventDefault();
						await api_feedback.submit({ token, rating: +rating, message });
						set_submitted(true);
					}}
				>
					<div className="header_block">
						<h2>Feedback Form</h2>
					</div>

					<h3>Overall, how was your experience in our facility?</h3>
					<div className="grade">
						<p>Poor</p>
						{[1, 2, 3, 4, 5].map((value) => (
							<span key={value} className={+rating === value ? 'selected' : ''} onClick={() => navigate(`/feedback/${token}/${value}`)}>
								{value}
							</span>
						))}
						<p>Exceptional</p>
					</div>

					<h3>Please share any comments, questions, or concerns.</h3>
					<textarea value={message} onChange={(e) => set_message(e.target.value)} name="message"></textarea>

					{organization_context.organization.feedback_add ? (
						<>
							<h3 class="margin_top">Additional Note</h3>
							<div dangerouslySetInnerHTML={{ __html: organization_context.organization.feedback_add_text }}></div>
						</>
					) : (
						<></>
					)}

					<button type="submit">Confirm</button>
				</form>
			</div>
		</div>
	);
};

export default FeedbackForm;
