import React, { useState, useContext } from 'react';

import api_users from '#api/users.js';

import UserContext from '#context/user.jsx';

const UsersContext = React.createContext();

const _fields_to_update = ['first_name', 'last_name', 'email'];

function UsersProvider(props) {
	const user_context = useContext(UserContext);
	const { session } = user_context;
	const [list, set_list] = useState(undefined);
	const [user, set_user] = useState(undefined);

	const get = async (user_id) => {
		set_user(undefined);
		const result = await api_users.get_profile({ session, user_id });
		set_user(result);
		return result;
	};

	const clear_list = async (data) => {
		set_list(undefined);
		set_list(await api_users.list({ session, ...data }));
	};

	const get_list = async (data, duplicate = false) => {
		const response = await api_users.list({ session, ...data });

		let new_list = new Map((store.list || []).map((el) => [el._id, el]));

		if (!duplicate)
			response.forEach((el) => {
				if (new_list.has(el._id)) {
					if (!duplicate) {
						get_list({ skip: 0, limit: list.length, ...data }, true);
						duplicate = true;
					}
				}
				new_list.set(el._id, el);
			});
		new_list = Array.from(new_list.values());

		set_list(new_list);

		const limit = data?.limit || 30;
		return !(response.length < limit);
	};

	const set = async (data) => {
		const result = await api_users.set_profile({ session, ...data });

		if (user_context.user._id === user._id) {
			user_context.user.first_name = data.first_name;
			user_context.user.last_name = data.last_name;
			user_context.user.email = data.email;
			user_context.user.phone = data.phone;
			if (data.photo !== undefined || result?.photo) user_context.user.photo = result?.photo;

			user_context.user = { ...user_context.user };
		}

		const new_list = [...list];
		const index = list.findIndex(({ _id }) => _id === data.user_id);
		if (index === -1) {
			return;
		}
		const list_entry = new_list[index];
		_fields_to_update.map((field) => (list_entry[field] = data[field]));
		if (data.photo !== undefined || result?.photo) list_entry.photo = result?.photo;
		new_list[index] = list_entry;
		set_list(new_list);
	};

	const exports = async (role) => {
		const result = await api_users.export({ session, role });
		return result;
	};

	const store = {
		get user() {
			return user;
		},
		set user(_) {},
		get list() {
			return list;
		},
		set list(_) {},
		get,
		set,
		get_list,
		clear_list,
		exports,
		api: {
			delete: async (user) => {
				await api_users.delete({ session, user });
				let entry = list.find(({ _id }) => user === _id);
				if (entry) {
					set_list(list.filter((entry1) => entry !== entry1));
				}
			},
			create: async (data) => {
				await api_users.create({ session, ...data });

				const list_entry = {};
				_fields_to_update.map((field) => (list_entry[field] = data[field]));
				set_list([...list, list_entry]);
			},
		},
	};

	return <UsersContext.Provider value={store}>{props.children}</UsersContext.Provider>;
}

export default UsersContext;
export { UsersProvider };
