import React from 'react';
import { NavLink } from 'react-router-dom';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function MonthPicker({ minimum, maximum, current = {}, change, get_link }) {
	let back = true;
	if (minimum) {
		if (current.year < minimum.year) {
			// 			change({...minimum});
			// 			return (<></>);
			back = false;
		} else if (current.year === minimum.year) {
			if (current.month < minimum.month) {
				// 				change({...minimum});
				// 				return (<></>);
				back = false;
			} else if (current.month === minimum.month) {
				back = false;
			}
		}
	}

	let forward = true;
	if (maximum) {
		if (current.year > maximum.year) {
			// 			change({...maximum});
			// 			return (<></>);
			forward = false;
		} else if (current.year === maximum.year) {
			if (current.month > maximum.month) {
				// 				change({...maximum});
				// 				return (<></>);
				forward = false;
			} else if (current.month === maximum.month) {
				forward = false;
			}
		}
	}

	const _calc = (dir) => {
		let month = current.month + dir;
		let year = current.year;
		if (month < 1) {
			year--;
			month = 12;
		}
		if (month > 12) {
			year++;
			month = 1;
		}
		if (minimum) {
			if (year < minimum.year) {
				year = minimum.year;
				month = minimum.month;
			} else if (year === minimum.year && month < minimum.month) {
				month = minimum.month;
			}
		}
		if (maximum) {
			if (year > maximum.year) {
				year = maximum.year;
				month = maximum.month;
			} else if (year === maximum.year && month > maximum.month) {
				month = maximum.month;
			}
		}
		return { year, month };
	};

	const _change = (e, dir) => {
		e.stopPropagation();
		e.preventDefault();
		change(_calc(dir));
	};

	return (
		<div className="month_picker">
			{get_link ? (
				<NavLink to={get_link(_calc(-1))} className={`arrow_left ${back ? '' : 'disabled'}`} />
			) : (
				<a href="#" onClick={(e) => _change(e, -1)} className={`arrow_left ${back ? '' : 'disabled'}`} />
			)}
			<span>
				{MONTHS[current.month - 1]} {current.year}
			</span>

			{get_link ? (
				<NavLink to={get_link(_calc(+1))} className={`arrow_right ${forward ? '' : 'disabled'}`} />
			) : (
				<a href="#" onClick={(e) => _change(e, +1)} className={`arrow_right ${forward ? '' : 'disabled'}`} />
			)}
		</div>
	);
}

export default MonthPicker;
