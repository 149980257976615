import React from 'react';
import { useLocation } from 'react-router-dom';
import ProfileInfo from '#right_sections/profile_info.jsx';
import BookingRequest from '#right_sections/booking_request.jsx';
import CustomInput from '#right_sections/custom_input.jsx';
import RoomView from '#right_sections/room.jsx';
import AssetView from '#right_sections/asset.jsx';

const _hash2components = new Map([
	['#profile', ProfileInfo],
	['#booking', BookingRequest],
	['#input', CustomInput],
	['#room', RoomView],
	['#asset', AssetView],
]);

const RightSection = () => {
	const location = useLocation();

	let shown = false;

	const data = location.hash.split('_');

	const Component = _hash2components.get(data.shift());

	let content = <></>;

	if (Component) {
		shown = true;
		content = <Component data={data} />;
	}

	return <section className={`dashboard_right_section${shown ? ' shown' : ''}`}>{content}</section>;
};

export default RightSection;
