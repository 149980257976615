import React from 'react';

const Stepper = ({ back_clb, step_1_clb, step_2_clb, step_3_clb, current_step, step_finished, disabled }) => {
	const get_cls = (step_index) => {
		let cls = '';

		if (current_step === step_index && !step_finished) {
			cls += 'active';
		} else if ((current_step === step_index && step_finished) || current_step > step_index) {
			cls += 'done';
		}

		switch (step_index) {
			case 1:
				cls += !step_1_clb ? ' disabled' : '';
				break;
			case 2:
				cls += !step_2_clb ? ' disabled' : '';
				break;
			case 3:
				cls += !step_3_clb ? ' disabled' : '';
				break;
			default:
				cls += 'disabled';
		}

		return cls;
	};

	return (
		<div className={`stepper_wrap${disabled ? ' disabled' : ''}`}>
			{back_clb ? (
				<a className="arrow_left" href="#!" onClick={(!disabled && back_clb) || undefined}>
					&nbsp;
				</a>
			) : (
				<></>
			)}
			<ul className="stepper">
				<li className={get_cls(1)} onClick={(!disabled && step_1_clb) || undefined}>
					<p>Rooms & Duration</p>
					<div className="step"></div>
				</li>
				<li className={get_cls(2)} onClick={(!disabled && step_2_clb) || undefined}>
					<p>Dates & Times</p>
					<div className="step"></div>
				</li>
				<li className={get_cls(3)} onClick={(!disabled && step_3_clb) || undefined}>
					<p>Details</p>
					<div className="step"></div>
				</li>
			</ul>
		</div>
	);
};

export default Stepper;
