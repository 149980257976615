import React, { createContext, useState, useEffect } from 'react';

const IsMobileContext = createContext();

function IsMobileProvider(props) {
	const [is_mobile, is_mobile_set] = useState(false);

	useEffect(() => {
		const on_resize = () => {
			is_mobile_set(window.innerWidth <= 767);
		};

		window.addEventListener('resize', on_resize);

		on_resize();

		return () => window.removeEventListener('resize', on_resize);
	}, []);

	return <IsMobileContext.Provider value={is_mobile}>{props.children}</IsMobileContext.Provider>;
}

export default IsMobileContext;
export { IsMobileProvider };
