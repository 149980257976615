import fetch from './fetch.js';
import post from './post.js';
import get from './get.js';

export default {
	for_new_booking: () => get('rooms/for_new_booking'),
	list_types: (session) => get('rooms/list_types', { session }),
	list_all: ({ session }) => get('rooms/list_all', { session }),
	get: ({ session, room }) => get('rooms/get', { session, room }),
	create: ({ session, name, capacity, cleanup, setup, description, photo, rooms }) => {
		const body = new FormData();
		body.append('session', session);
		body.append('name', name);
		if (photo) body.append('photo', photo);

		body.append('capacity', capacity);
		body.append('cleanup', cleanup);
		body.append('setup', setup);
		body.append('description', description);
		body.append('rooms', JSON.stringify(rooms));
		return fetch('rooms/create', body);
	},
	update: ({ session, room, name, capacity, cleanup, setup, description, photo, rooms }) => {
		const body = new FormData();
		body.append('session', session);
		body.append('room', room);
		body.append('name', name);
		if (photo || photo === false) body.append('photo', photo);

		body.append('capacity', capacity);
		body.append('cleanup', cleanup);
		body.append('setup', setup);
		body.append('description', description);
		body.append('rooms', JSON.stringify(rooms));
		return fetch('rooms/update', body);
	},
	delete: ({ session, room }) => post('rooms/delete', { session, room }),
	update_indexes: ({ session, indexes }) => post('rooms/update/indexes', { session, indexes }), //indexes - array of {custom_input:<_id>,index:<number>}
};
