import React, { useState, useContext, useEffect } from 'react';

import api_organization from '#api/organization.js';

import Loader from '#components/loader.jsx';

import UserContext from '#context/user.jsx';

const OrganizationContext = React.createContext();

function OrganizationProvider(props) {
	const { session } = useContext(UserContext);
	const [organization, organization_set] = useState(undefined);

	const _get = async () => {
		const data = await api_organization.get();
		document.title = `${data.name} | theSimCal`;
		organization_set({ ...data });
		return data;
	};

	const _set = async (data) => {
		const result = await api_organization.set({ session, organization: data.uuid, ...data });
		const new_data = { ...organization, ...data };
		if (data.logo !== undefined || result?.logo) new_data.logo = result?.logo;
		organization_set(new_data);
	};

	const _file_upload = async (file_upload) => {
		file_upload = !!file_upload;
		await api_organization.file_upload({ session, organization: organization.uuid, file_upload });
		organization_set({ ...organization, file_upload });
	};

	const _feedback_add = async ({ add, text }) => {
		add = !!add;
		await api_organization.feedback_add({ session, organization: organization.uuid, add, text });
		organization_set({ ...organization, feedback_add: add, feedback_add_text: text });
	};

	const store = {
		get organization() {
			return organization;
		},
		set organization(_) {},
		get: _get,
		set: _set,
		file_upload: _file_upload,
		feedback_add: _feedback_add,
	};

	useEffect(() => {
		_get();
	}, [1]);

	return <OrganizationContext.Provider value={store}>{props.children}</OrganizationContext.Provider>;
}

function OrganizationDeffer({ children }) {
	const context = useContext(OrganizationContext);

	if (!context.organization) return <Loader />;

	return children;
}

export default OrganizationContext;
export { OrganizationProvider, OrganizationDeffer };
