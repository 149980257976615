import React, { useState, useContext, useRef } from 'react';
import api_emails from '#api/emails.js';

import ModalsContext from '#context/modals.jsx';
import OrganizationContext from '#context/organization.jsx';

import ReCAPTCHA from 'react-google-recaptcha';
import CONFIG from '#config.json';

const ContactUs = () => {
	const modals_context = useContext(ModalsContext);
	const organization_context = useContext(OrganizationContext);
	const { email, phone, address1, country, city, province, postal, address2 } = organization_context.organization;

	const [loading, set_loading] = useState(false);
	const [error, set_error] = useState('');
	const [state, set_state] = useState('form');
	const [captcha, set_captcha] = useState(false);
	const [data, set_data] = useState({
		name: '',
		email: '',
		message: '',
	});

	const captchaRef = useRef(null);
	let cls = 'contact_us';

	const on_submit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		set_loading(true);

		const token = captchaRef.current.getValue();

		(async () => {
			try {
				await api_emails.contact_us({ ...data, token });
				set_state('delivered');
				set_loading(false);
			} catch (e) {
				set_error(e);
				set_loading(false);
			}
		})();

		return false;
	};

	const on_change = (e) => {
		set_data({ ...data, [e.target.name]: e.target.value });
		set_error('');
	};

	let content;

	switch (state) {
		case 'form':
			content = (
				<>
					<h3>Contact Us</h3>
					<div className="wrapper">
						<form action="#" className="account_form" onSubmit={on_submit}>
							<div className="input">
								<label htmlFor="last_name">Your Name</label>
								<input type="text" name="name" id="name" placeholder="&nbsp;" required value={data.name} onChange={on_change} />
							</div>

							<div className="input">
								<label htmlFor="email">Your Email</label>
								<input type="email" name="email" id="email" placeholder="&nbsp;" required value={data.email} onChange={on_change} />
							</div>

							<div className={`input message${error ? ' with_error' : ''}`}>
								<div className="title">Your Message</div>
								<textarea name="message" id="message" placeholder="&nbsp;" value={data.message} onChange={on_change}></textarea>
								<span className="error">{error}</span>
							</div>

							<div className="captcha">
								<ReCAPTCHA sitekey={CONFIG.recaptcha} onChange={(value) => set_captcha(value)} hl={'en'} ref={captchaRef} />
							</div>
							<button type="submit" disabled={!loading && data.name && data.email && data.message && captcha ? '' : 'disabled'}>
								Send Message
							</button>
						</form>

						<div className={`info${phone && email ? '' : ' without_phone_email'}${!phone && email ? ' without_phone' : ''}`}>
							{phone && (
								<a className="phone" href={`tel:${phone}`}>
									{phone}
								</a>
							)}
							{email && (
								<a className="email" href={`mailto:${email}`}>
									{email}
								</a>
							)}
							{address1 || address2 || country || city || province ? (
								<a className="map" href="#!">
									<div>{address1}</div>
									<div>{address2}</div>
									<div>{`${city}${province ? `, ${province}` : ''}${postal ? `, ${postal}` : ''}`}</div>
									<div>{country}</div>
								</a>
							) : (
								<></>
							)}
						</div>
					</div>
				</>
			);
			break;
		case 'delivered':
			cls = 'success delivered';
			content = (
				<div className="info">
					<div className="check">
						<div className="img"></div>
						<h3>Message Delivered</h3>
					</div>
					<p className="text">We will be in touch with you soon</p>
					<a className="btn_continue" href="#" onClick={modals_context.hide}>
						Continue
					</a>
				</div>
			);
			break;
		default:
			break;
	}

	return (
		<div className={`popup ${cls}`}>
			<a className="btn_close" href="#" onClick={modals_context.hide}></a>
			{content}
		</div>
	);
};

export default ContactUs;
