import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import RoomsContext from '#context/rooms.jsx';
import ModalsContext from '#context/modals.jsx';
import Loader from '#components/loader.jsx';
import Dropdown from '#components/dropdown.jsx';

import { pretty_period } from '#components/time_helpers.js';

const _generate_list = () => {
	const from = 0;
	const to = 24;
	const list = [];

	for (let i = from; i < to; i += 0.5) list.push({ view: pretty_period(i), value: i });

	return list;
};

const check_rooms = (rooms, new_room) => {
	const same_room = rooms.find((room) => room.name === new_room.name);
	if (same_room) {
		new_room._id = same_room._id;
	}
	return new_room;
};

const capitalize_first_letter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const _schedules = ['setup', 'cleanup'];
const def_type = {
	capacity: '',
	cleanup: 0,
	count: 0,
	description: '',
	name: '',
	photo: null,
	rooms: [{ name: '' }],
	setup: 0,
};

const Actions = ({ handle_close, is_creating }) => (
	<div className={`actions ${is_creating ? 'create' : ''}`}>
		{is_creating ? (
			<></>
		) : (
			<button type="reset" onClick={handle_close}>
				Discard Changes
			</button>
		)}
		<button type="submit">{is_creating ? 'Add Room Type' : 'Save Changes'}</button>
	</div>
);

const RoomDescription = ({ type, handle_change, handle_change_file, preview, delete_file }) => (
	<div className="block">
		<div className="input">
			<label htmlFor="description">Room Description</label>
			<textarea name="description" onChange={handle_change} defaultValue={type.description === 'undefined' ? '' : type.description} />
		</div>

		<div className={`photo ${preview ? 'with_photo' : 'without_photo'}`}>
			<p>Room Photo, recommended size: 600px tall x 600px wide</p>
			<label className="input">
				<input type="file" onChange={handle_change_file} />
				<div className="image">
					<span className="action">+</span>
					{preview ? <img src={preview} width="100px" height="100px" /> : <></>}
				</div>
			</label>

			<label className="button">
				<input type="file" onChange={handle_change_file} />
				Select File
			</label>
			<a className="icon border red delete_red" onClick={delete_file}></a>
		</div>
	</div>
);

const RoomSchedule = ({ type, handle_change_dropdown }) => {
	const list = _generate_list();
	return (
		<div className="block schedule">
			<h3>Estimated Setup & Cleanup</h3>
			<p>Times will automatically be added to the duration to find available time during your hours of operation</p>
			<ul>
				{_schedules.map((name) => (
					<li key={name}>
						<p>{capitalize_first_letter(name)}</p>
						<Dropdown list={list} selection={pretty_period(type[name])} selected={true} clb={(value) => handle_change_dropdown(value, name)} />
					</li>
				))}
			</ul>
		</div>
	);
};

const RoomCapacity = ({ type, handle_change }) => (
	<div className="block in_row">
		<div className="input">
			<label htmlFor="capacity">Room Capacity</label>

			<input type="number" name="capacity" value={type.capacity} onChange={handle_change} />
		</div>
	</div>
);

const RoomInformation = ({ type, handle_change, set_type, change }) => {
	const handle_change_rooms = (e, index) => {
		const { name, value } = e.target;
		const updated_rooms = type.rooms.map((el, i) => (i === index ? { ...el, [name]: value } : el));

		set_type({ ...type, rooms: updated_rooms });
		change();
	};

	return (
		<div className="block">
			<div className="input">
				<label htmlFor="name">Name of Room Type*</label>
				<input type="text" name="name" value={type.name} onChange={handle_change} required />
			</div>

			<div className="add_item">
				<h3>Rooms</h3>
				{type.rooms.map((el, i) => (
					<div className="input" key={i}>
						<label htmlFor="name">Room #*</label>
						<input type="text" name="name" value={el.name} onChange={(e) => handle_change_rooms(e, i)} required />
						{type.rooms.length === 1 ? (
							<></>
						) : (
							<a
								className="icon close_small"
								onClick={() => {
									type.rooms.splice(i, 1);
									set_type({ ...type, rooms: type.rooms });
									change();
								}}
							/>
						)}
					</div>
				))}
				<a onClick={() => set_type({ ...type, rooms: [...type.rooms, { name: '' }] })}>+ Add room</a>
			</div>
		</div>
	);
};

const Header = ({ handle_close, handle_delete, is_creating }) => (
	<div className="header_block">
		<a className="icon border close" onClick={handle_close}></a>
		<h2>{is_creating ? 'New Room Type' : 'Edit Room Type'}</h2>
		<div className="actions">
			<a className="icon border red delete_red" onClick={is_creating ? handle_close : handle_delete}></a>
		</div>
	</div>
);

const RoomView = ({ data }) => {
	const navigate = useNavigate();

	const rooms_context = useContext(RoomsContext);
	const modals_context = useContext(ModalsContext);

	const [is_creating, set_is_creating] = useState(false);
	const [type, set_type] = useState();
	const [changed, set_changed] = useState(false);
	const [selected_file, set_selected_file] = useState();
	const [preview, set_preview] = useState('');
	const [rooms_def, set_rooms_def] = useState();

	const change = () => {
		if (!changed) set_changed(true);
	};

	useEffect(() => {
		if (!data[0]) {
			set_is_creating(true);
			set_changed(false);
			set_type(def_type);
			return;
		}

		(async () => {
			set_is_creating(false);
			set_changed(false);
			set_type();
			const result = await rooms_context.get(data[0]);
			set_type(result);
			set_selected_file(result?.photo);
			set_rooms_def([...(result?.rooms || [])]);
		})();
	}, [data[0]]);

	useEffect(() => {
		set_preview(type?.photo);
	}, [type?.photo]);

	const handle_change_file = (event) => {
		const file = event.target.files[0];
		set_selected_file(file);

		const fileUrl = URL.createObjectURL(file);
		set_preview(fileUrl);
		change();
	};

	if (!type) return <Loader />;

	const handle_change = (e) => {
		const { name, value } = e.target;
		set_type((type) => ({ ...type, [name]: value }));
		change();
	};

	const handle_change_dropdown = (value, name) => {
		const new_type = { ...type };
		new_type[name] = value;
		set_type(new_type);
		change();
	};

	const delete_file = () => {
		set_selected_file(false);
		set_preview('');
		change();
	};

	const handle_close = () => {
		if (!changed) {
			navigate('#');
			return;
		}

		modals_context.show('room_action', {
			action: 'leave',
			clb: () => navigate('#'),
		});
	};

	const handle_delete = () =>
		modals_context.show('room_action', {
			action: 'delete',
			clb: () => {
				rooms_context.api.delete(type._id);
				navigate('#');
			},
		});

	const handle_submit = async (e) => {
		e.preventDefault();

		if (!changed) return;

		const new_type = { ...type };

		if (!is_creating) {
			const checked_rooms = new_type.rooms.map((type) => check_rooms(rooms_def, type));
			new_type.rooms = checked_rooms;
		}

		if (selected_file === false || (selected_file && selected_file !== type.photo)) {
			new_type.photo = selected_file;
		} else {
			delete new_type.photo;
		}

		try {
			modals_context.show(
				'saved_changes',
				is_creating ? { text: `You’ve successfully added <b>“${new_type.name}”</b> room type ` } : { state: 'column' }
			);
			is_creating ? await rooms_context.api.create(new_type) : await rooms_context.api.update(new_type);
			navigate('#');
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<div className="room_item">
			<Header handle_close={handle_close} handle_delete={handle_delete} is_creating={is_creating} />
			<form onSubmit={handle_submit}>
				<RoomInformation type={type} handle_change={handle_change} set_type={set_type} rooms_def={rooms_def} change={change} />
				<RoomCapacity type={type} handle_change={handle_change} />
				<RoomSchedule type={type} handle_change_dropdown={handle_change_dropdown} />
				<RoomDescription
					type={type}
					handle_change={handle_change}
					handle_change_file={handle_change_file}
					preview={preview}
					delete_file={delete_file}
				/>
				{changed ? <Actions handle_close={handle_close} is_creating={is_creating} /> : <></>}
			</form>
		</div>
	);
};

export default RoomView;
