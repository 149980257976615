import React from 'react';

import { UserDeffer } from '#context/user.jsx';

import { Outlet } from 'react-router-dom';

import Sidebar from '#components/sidebar.jsx';

import RightSection from '#controllers/dashboard_right_section.jsx';

import { useNavigate } from 'react-router-dom';

const DashboardLayout = () => {
	const navigate = useNavigate();

	return (
		<UserDeffer
			fallback={() => {
				navigate('/');
			}}
		>
			<div className="dashboard">
				<Sidebar />
				<div className="main_area">
					<Outlet />
				</div>
				<RightSection />
			</div>
		</UserDeffer>
	);
};

export default DashboardLayout;
