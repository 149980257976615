import { useContext } from 'react';
import ModalsContext from '#context/modals';

const NotificationDelete = ({ clb }) => {
	const modals_context = useContext(ModalsContext);

	const on_submit = (e) => {
		e.stopPropagation();
		e.preventDefault();
		clb();
		modals_context.hide();
	};

	return (
		<div className="popup">
			<form className="account_form booking" onSubmit={on_submit}>
				<h3>Delete Notification?</h3>
				<p>Are you sure you want to delete this notification? You can’t undo this action.</p>

				<div className="actions">
					<button
						onClick={(e) => {
							e.preventDefault();
							modals_context.hide();
						}}
					>
						No
					</button>

					<button type="submit">Yes</button>
				</div>
			</form>
		</div>
	);
};

export default NotificationDelete;
