import React, { useContext, useState } from 'react';

import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

import ModalsContext from '#context/modals.jsx';
import bookingsContext from '#context/bookings.jsx';

const EditRequest = () => {
	const modals_context = useContext(ModalsContext);
	const bookings_context = useContext(bookingsContext);

	if (modals_context.data.Attendees) delete modals_context.data.Attendees;

	const [data, set_data] = useState(modals_context.data);

	const on_change = ({ target: { name, value } }) => {
		set_data({ ...data, [name]: value });
	};

	const on_submit = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		await bookings_context.api.update_request(data);
		modals_context.hide();

		return false;
	};

	return (
		<div className="popup">
			<form className="account_form booking" onSubmit={on_submit}>
				<h3>Session Information</h3>
				<div className="input">
					<label htmlFor="name">
						Name<span>*</span>
					</label>
					<input type="text" name="name" id="name" placeholder="&nbsp;" required value={data.name} onChange={on_change} />
					<span class="error"></span>
				</div>
				<div className="input">
					<label htmlFor="email">
						Email<span>*</span>
					</label>
					<input type="email" name="email" id="email" placeholder="&nbsp;" required value={data.email} onChange={on_change} />
					<span class="error"></span>
				</div>
				<div className="input">
					<label htmlFor="notification_emails">CC Email Address(es) for Notifications</label>
					<ReactMultiEmail
						name="notification_emails"
						emails={data.notification_emails}
						onChange={(value) => on_change({ target: { name: 'notification_emails', value } })}
						autoFocus={false}
						getLabel={(email, index, remove) => {
							return (
								<div data-tag key={index}>
									<div data-tag-item>{email}</div>
									<span data-tag-handle onClick={() => remove(index)}>
										×
									</span>
								</div>
							);
						}}
					/>
					<span className="error"></span>
				</div>
				<div className="input">
					<label htmlFor="phone">Phone Number</label>
					<input type="tel" name="phone" id="phone" placeholder="&nbsp;" value={data.phone} onChange={on_change} />
					<span class="error"></span>
				</div>
				<div className="input">
					<label htmlFor="session_name">
						Session Name<span>*</span>
					</label>
					<input type="text" name="session_name" id="session_name" placeholder="&nbsp;" required value={data.session_name} onChange={on_change} />
					<span class="error"></span>
				</div>
				<div className="input">
					<label htmlFor="attendees">
						Number of Attendees<span>*</span>
					</label>
					<input type="text" name="attendees" id="attendees" placeholder="&nbsp;" required value={data.attendees} onChange={on_change} />
					<span class="error"></span>
				</div>
				<div className="input message">
					<label htmlFor="details">Details</label>
					<textarea name="details" id="details" placeholder="&nbsp;" value={data.details} onChange={on_change} />
					<span class="error"></span>
				</div>

				<div className="actions">
					<button
						onClick={(e) => {
							e.preventDefault();
							modals_context.hide();
						}}
					>
						Cancel
					</button>

					<button type="submit">Save</button>
				</div>
			</form>
		</div>
	);
};

export default EditRequest;
