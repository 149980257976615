import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Headerbar from '#components/headerbar.jsx';
import Stepper from '#components/booking_stepper.jsx';

import Dropdown from '#components/dropdown.jsx';

import ModalsContext from '#context/modals.jsx';

import NewBookingContext from '#context/new_booking.jsx';

import OrganizationContext from '#context/organization.jsx';

import { pretty_period } from '#components/time_helpers.js';

const RoomDropdown = ({ selected, total, clb, disabled }) => {
	const list = [];
	for (let i = 0; i < total + 1; i++) list.push({ chosen: selected === i, view: i, value: i });

	return <Dropdown selection={selected || '0'} selected={selected > 0} list={list} clb={clb} disabled={disabled} />;
};

const Room = (room) => {
	const modals_context = useContext(ModalsContext);
	const new_booking_context = useContext(NewBookingContext);

	const { _id, name, capacity, photo, count, setup, cleanup, description } = room;
	const max_duration = new_booking_context.get_max_duration_for_room(room);

	return (
		<li className={`item${new_booking_context.selected_rooms[_id] ? ' selected' : ''}`}>
			<img src={photo || '/img/no_room_images.jpg'} alt="image" />
			<div className="info">
				<div className="text">
					<h5>{name}</h5>
					<div className="duration">
						Maximum Duration: {max_duration} Hour{max_duration > 1 ? 's' : ''}
					</div>
					<p className="details_capacity">
						<a
							href="#!"
							onClick={() => {
								modals_context.show('room_details', {
									name,
									capacity,
									max_duration,
									setup,
									cleanup,
									description,
									photo,
								});
							}}
						>
							View Details
						</a>
						Capacity: {capacity}
					</p>
				</div>
				<RoomDropdown
					total={count}
					selected={new_booking_context.selected_rooms[_id] || 0}
					disabled={max_duration <= 0}
					clb={(value) => {
						let new_rooms = { ...new_booking_context.selected_rooms, [_id]: value };
						if (new_rooms[_id] === 0) delete new_rooms[_id];
						new_booking_context.selected_rooms = new_rooms;
					}}
				/>
			</div>
		</li>
	);
};

//TODO further logic

const BookingRooms = ({ keep = true }) => {
	const navigate = useNavigate();

	const { organization } = useContext(OrganizationContext);
	const new_booking_context = useContext(NewBookingContext);
	const modals_context = useContext(ModalsContext);

	const [mobile_step_2, set_mobile_step_2] = useState(new_booking_context.duration > 0);

	const total_rooms = new_booking_context.total_rooms;

	const durations = new_booking_context.durations.map((value) => ({
		chosen: value === new_booking_context.duration,
		view: pretty_period(value),
		value,
	}));

	const is_finish = total_rooms && new_booking_context.duration;

	useEffect(() => {
		//I don't like this solution
		if (!keep) new_booking_context.reset();
	}, [keep]);

	const next_click = () => {
		set_mobile_step_2(true);
	};

	const finished = () => {
		navigate('/new_booking/datetimes');
	};

	return (
		<div className="app2">
			<Headerbar page="/new_booking/rooms" back_clb={() => set_mobile_step_2(false)} is_first_page={!mobile_step_2} />
			<div className={`top_warning ${new_booking_context.warning ? '' : 'hidden'}`}>{new_booking_context.warning}</div>
			<Stepper step_2_clb={is_finish ? finished : null} current_step={1} step_finished={is_finish} />
			<div className={`rooms_duration_container${total_rooms > 0 ? ' selected' : ''}${mobile_step_2 ? ' mobile_step_2' : ''}`}>
				<div className="rooms">
					<h4>Select Room Types & Quantities</h4>
					<ul className="list">
						{new_booking_context.rooms.map((room) => (
							<Room key={room._id} {...room} />
						))}
					</ul>
					<div className="data">
						{organization.edit_times ? (
							<a
								href="#!"
								onClick={() => {
									modals_context.show('setup_cleanup', new_booking_context);
								}}
							>
								Setup & Cleanup
							</a>
						) : (
							<a href="#!"></a>
						)}

						<div className="total">Room Total: {total_rooms}</div>
					</div>
				</div>
				<div className="duration">
					<h4>Select Duration</h4>
					<div className="warning">Please select rooms types and quantities first</div>
					<Dropdown
						cls="time"
						selection={pretty_period(new_booking_context.duration)}
						selected={new_booking_context.duration > 0}
						list={durations}
						clb={(value) => {
							new_booking_context.duration = value;
						}}
					/>
					<div className="total_mobile">Room Total: {total_rooms}</div>
					<a className={`btn_next mobile_step_2${is_finish ? '' : ' disabled'}`} href="#!" onClick={finished}>
						Next
					</a>
					<a className={`btn_next mobile_step_1${total_rooms ? '' : ' disabled'}`} href="#!" onClick={next_click}>
						Next
					</a>
				</div>
			</div>
		</div>
	);
};

export default BookingRooms;
