import React, { useState, useContext, useEffect } from 'react';
import user_api from '#api/user.js';

import ModalsContext from '#context/modals.jsx';

const ForgotPassword = () => {
	const modals_context = useContext(ModalsContext);
	const { initial_email } = modals_context.data;

	const [error, set_error] = useState('');

	const [loading, set_loading] = useState(false);

	const [state, set_state] = useState('form');

	const [email, set_email] = useState(initial_email);

	useEffect(() => {
		return () => {
			set_state('form');
			set_loading(false);
			set_email(email);
			set_error('');
		};
	}, []);

	const on_submit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		set_loading(true);

		(async () => {
			try {
				await user_api.forgot_password(email);
				set_loading(false);
				set_state('sent');
			} catch (e) {
				set_loading(false);
				set_error("There's no user with this email");
			}
		})();

		return false;
	};

	let content;

	switch (state) {
		case 'form':
			content = (
				<form action="#" className="account_form" onSubmit={on_submit}>
					<h3>Reset Password</h3>
					<div className={`input${error ? ' with_error' : ''}`}>
						<label htmlFor="email">Email</label>
						<input
							type="email"
							name="email"
							id="email"
							value={email}
							onChange={(e) => {
								set_email(e.target.value);
								set_error('');
							}}
							placeholder="&nbsp;"
							required
						/>
						<span className="error">{error}</span>
					</div>
					<button type="submit" disabled={!loading && email ? '' : 'disabled'}>
						Send Reset Email
					</button>
				</form>
			);
			break;
		case 'sent':
			content = (
				<div className="info">
					<div className="check">
						<div className="img"></div>
						<h3>Check Your Mail</h3>
					</div>
					<p className="text">We have sent password recover instructions to your email.</p>
					<a className="btn_continue" href="#!" onClick={modals_context.hide}>
						Continue
					</a>
				</div>
			);
			break;
		default:
			break;
	}

	return (
		<div className={`popup ${state === 'sent' ? ' success' : ''}`}>
			<a className="btn_close" href="#!" onClick={modals_context.hide}></a>
			{content}
		</div>
	);
};

export default ForgotPassword;
